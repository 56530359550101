/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import './productOwnerNotifications.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import InfiniteScroll from 'react-infinite-scroll-component';
import Avatar from '@material-ui/core/Avatar';
import dayjs from 'dayjs';
import { readAllNotifications, deleteAllNotifications } from 'app/services/notifications/notifications';
import { useDispatch } from 'react-redux';
import * as Actions from 'app/store/actions';

const Notifications = ({
	notifications = [],
	handleLoadMore,
	hasMore,
	loadingNotifications,
	fetchingMore,
	setUnreadCount,
	setNotifications,
}) => {
	const [userMenu, setUserMenu] = useState(null);
	const dispatch = useDispatch();

	const userMenuClick = (event) => {
		readAllNotifications().then(() => {
			setUnreadCount(0);
		});
		setUserMenu(event.currentTarget);
	};

	const userMenuClose = () => {
		setUserMenu(null);
	};

	const readAllNotification = () => {
		deleteAllNotifications()
			.then(() => {
				setNotifications([]);
			})
			.catch((resp) => {
				dispatch(
					Actions.showMessage({
						message: resp.data.message,
						autoHideDuration: 1500,
						variant: 'success',
					}),
				);
			});
	};

	return (
		<>
			<Button onClick={userMenuClick}>
				<div className="noti-wrapper">
					<div className="noti-icon-wrapper">
						<img src="assets/images/schoolAdminTopNav/noti-icon.svg" alt="notification" />
					</div>
					<div className="noti-text">Notifications</div>
				</div>
			</Button>
			<Popover
				open={Boolean(userMenu)}
				anchorEl={userMenu}
				className="mt-6"
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				{notifications?.length > 0 ? (
					<div className="flex justify-between">
						<div></div>
						<div className="cursor-pointer delete-notification" onClick={() => readAllNotification()}>
							Clear all
						</div>
					</div>
				) : null}

				<div id="scrollableDiv" style={{ height: 450, width: '460px', overflow: 'auto' }}>
					<InfiniteScroll
						dataLength={notifications.length}
						next={handleLoadMore}
						hasMore={hasMore}
						scrollableTarget="scrollableDiv"
					>
						{loadingNotifications ? (
							<div
								style={{ height: '450px' }}
								className="text-center flex flex-col justify-center items-center"
							>
								<CircularProgress size={30} />
							</div>
						) : (
							<>
								{notifications.length > 0 ? (
									notifications.map((notification, index) => (
										<div key={notification.id}>
											<div className="notification-wrapper border-b">
												<div className="flex" style={{ gap: '5px' }}>
													<div className="">
														<Avatar
															style={{ width: '35px', height: '35px' }}
															src={
																notification?.data?.thumb || 'assets/images/school.png'
															}
														/>
													</div>
													<div className="flex flex-col flex-grow">
														<span
															className="font-bold"
															style={{ fontSize: '14px', marginBottom: '4px' }}
														>
															{notification.data?.title}
														</span>
														<span style={{ fontSize: '12px' }}>
															<span>{notification.data?.body}</span>
														</span>
													</div>
													<div className="flex flex-col items-end" style={{ flexShrink: 0 }}>
														<span style={{ fontSize: '11px', marginTop: '4px' }}>
															{dayjs(notification.data.created_at).format(
																'MMMM[ ] D[,] YYYY[,] hh[:]mm[ ]A',
															)}
														</span>
													</div>
												</div>
											</div>
										</div>
									))
								) : (
									<div className="flex justify-center items-center">
										<h4>No Notifications</h4>
									</div>
								)}
							</>
						)}
						{fetchingMore && (
							<div style={{ padding: '2rem' }}>
								<div className="flex justify-center">
									<CircularProgress size={35} />
								</div>
							</div>
						)}
					</InfiniteScroll>
				</div>
			</Popover>
		</>
	);
};

export default Notifications;
