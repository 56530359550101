/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import history from '@history';
import {
	CircularProgress,
	Avatar,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormHelperText,
	Button,
} from '@material-ui/core';
import {
	getCompany,
	getSearchableStateList,
	getCountryList,
	getSearchableCityList,
	updateCompany,
} from 'app/services/Company/companyService';
import { uploadFile } from 'app/services/imageUpload/imageUpload';
import * as Actions from 'app/store/actions';
import FuseAnimate from '@fuse/core/FuseAnimate';
import CustomButton from 'app/customComponents/CustomButton/CustomButton';
import { useDispatch } from 'react-redux';
import './Company.css';
import { useParams } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getImageUrl } from 'utils/utils';
import tzlookup from 'tz-lookup';

function EditCompany() {
	const dispatch = useDispatch();
	const [form, setForm] = useState({ status: 1 });
	const [errTxts, setErrTxts] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [countries, setCountries] = useState([]);
	const [states, setStates] = useState([]);
	const [timeZone, setTimeZone] = useState({ city: '', state: '' });
	const [cityTimezone, setCityTimeZone] = useState('');
	const [latLong, setLatLong] = useState({ lat: '', long: '' });
	const [city, setCity] = useState([]);
	const { id } = useParams();
	const [selectedFile, setSelectedFile] = useState(null);
	const [preview, setPreview] = useState(null);
	const inputRef = useRef(null);
	const [isStateloading, setIsStateloading] = useState(false);
	const [searchStateQuery, setStateSearchQuery] = useState('');
	const [isCityLoading, setIsCityLoading] = useState(false);
	const [searchCityQuery, setSearchCityQuery] = useState('');

	const [isUpdating, setIsUpdating] = useState(false);
	const [defaultCity, setDefaultCity] = useState();
	const [defaultState, setDefaultState] = useState({});

	const handleChange = (ev) => {
		const { name, value } = ev.target;
		setErrTxts({ ...errTxts, [name]: [] });
		if (name == 'city') {
			setTimeZone({ ...timeZone, city: value });
		}
		if (name == 'state') {
			setTimeZone({ ...timeZone, state: value });
		}
		if (name === 'country_code') {
			setErrTxts({ ...errTxts, country_code: '' });
			setForm({ ...form, country_code: value, state_id: '', city: '' });
			return;
		}
		if (name === 'state_id') {
			setErrTxts({ ...errTxts, state_id: '' });
			setForm({ ...form, state_id: value, city: '' });
			return;
		}
		setForm({ ...form, [name]: value });
	};

	useEffect(() => {
		let isMounted = true;
		getCountryList()
			.then((res) => {
				if (!isMounted) return;
				setCountries(res.data);
			})
			.catch((err) => {
				if (!isMounted) return;
				dispatch(
					Actions.showMessage({
						message: 'Failed to get countries.',
						autoHideDuration: 1500,
						variant: 'error',
					}),
				);
			});
		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(() => {
			if (!isMounted) return;
			setIsStateloading(true);
			setStates([]);
			getSearchableStateList(searchStateQuery, searchStateQuery ? undefined : 1)
				.then((res) => {
					if (!isMounted) return;
					setStates(res.data.data);
				})
				.catch((err) => {
					if (!isMounted) return;
					dispatch(
						Actions.showMessage({
							message: 'Failed to get states.',
							autoHideDuration: 1500,
							variant: 'error',
						}),
					);
				})
				.finally(() => {
					if (!isMounted) return;
					setIsStateloading(false);
				});
		}, 1000);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
	}, [dispatch, form.country_code, searchStateQuery]);

	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(() => {
			if (!isMounted) return;
			setIsCityLoading(true);
			setCity([]);
			if (!searchCityQuery && !form.state_id) {
				setCity([]);
			} else {
				getSearchableCityList(form.state_id, searchCityQuery, 1)
					.then((res) => {
						if (!isMounted) return;
						setCity(res.data.data);
					})
					.catch((err) => {
						if (!isMounted) return;
						dispatch(
							Actions.showMessage({
								message: 'Failed to get cities.',
								autoHideDuration: 1500,
								variant: 'error',
							}),
						);
					})
					.finally(() => {
						if (!isMounted) return;
						setIsCityLoading(false);
					});
			}
		}, 1000);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
	}, [form.state_id, searchCityQuery]);

	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(() => {
			if (!isMounted) return;
			setIsLoading(true);
			getCompany(id)
				.then((res) => {
					if (!isMounted) return;
					setForm({
						...form,
						id: res.data.id,
						name: res.data.name,
						email: res.data.admins[0].email,
						address: res.data.address,
						state_id: res.data.state_id,
						address2: res.data.address2,
						city: res.data.city,
						zip_code: res.data.zip_code,
						time_zone: cityTimezone,
						country_code: res.data.country_code,
						status: res.data.status,
						logo: res.data.logo,
						phone: res.data.phone,
					});
					setDefaultState(res.data.state);
					setDefaultCity({ name: res.data.city });
				})
				.catch((err) => {
					if (!isMounted) return;
					dispatch(
						Actions.showMessage({
							message: 'Failed to get company details.',
							autoHideDuration: 1500,
							variant: 'error',
						}),
					);
					setIsLoading(false);
				})
				.finally(() => {
					if (!isMounted) return;
					setIsLoading(false);
				});
		}, 1000);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
	}, [id]);

	useEffect(() => {
		if (form.state_id && states.length > 0) {
			setDefaultState({ id: defaultState.id, name: defaultState.name });
			setDefaultCity({ name: form.city });
		}
	}, [isLoading, states]);

	useEffect(() => {
		if (!selectedFile) {
			setPreview(form?.logo || null);
			return;
		}

		const objectUrl = URL.createObjectURL(selectedFile);
		setPreview(objectUrl);

		return () => URL.revokeObjectURL(objectUrl);
	}, [selectedFile, form?.logo]);

	const onSelectFile = (e) => {
		if (!e.target.files || e.target.files.length === 0) {
			setSelectedFile(form?.logo || null);
			return;
		}

		setSelectedFile(e.target.files[0]);
	};

	const handleSubmit = () => {
		setErrTxts({});

		if (selectedFile) {
			const filename = getImageUrl(selectedFile);
			setIsUpdating(true);
			uploadFile(selectedFile, filename).then((response) => {
				form.file = `${import.meta.env.VITE_S3_BASE_URL}${response}`;
				const payload = {
					name: form.name,
					address: form.address,
					state_id: form.state_id,
					address2: form.address2,
					city: form.city,
					zip_code: form.zip_code,
					time_zone: cityTimezone,
					country_code: form.country_code,
					status: form.status,
					file: form.file,
					phone: form.phone,
				};
				updateCompany(form.id, payload)
					.then((resp) => {
						dispatch(
							Actions.showMessage({
								message: resp.data.message,
								autoHideDuration: 1500,
								variant: 'success',
							}),
						);
						history.goBack('./Company');
					})
					.catch((err) => {
						if (err.response?.data?.errors) {
							setErrTxts(err.response.data.errors);
						} else {
							dispatch(
								Actions.showMessage({
									message: 'Failed to Update Company.',
									autoHideDuration: 1500,
									variant: 'error',
								}),
							);
						}
					})
					.finally(() => setIsUpdating(false));
			});
		} else {
			setIsUpdating(true);
			const payload = {
				name: form.name,
				address: form.address,
				state_id: form.state_id,
				address2: form.address2,
				city: form.city,
				zip_code: form.zip_code,
				time_zone: cityTimezone,
				country_code: form.country_code,
				status: form.status,
				phone: form.phone,
				file: form.logo,
			};
			updateCompany(form.id, payload)
				.then((resp) => {
					dispatch(
						Actions.showMessage({
							message: resp.data.message,
							autoHideDuration: 1500,
							variant: 'success',
						}),
					);
					history.goBack();
				})
				.catch((err) => {
					if (err.response?.data?.errors) {
						setErrTxts(err.response.data.errors);
					} else {
						dispatch(
							Actions.showMessage({
								message: 'Failed to Update Company.',
								autoHideDuration: 1500,
								variant: 'error',
							}),
						);
						setIsUpdating(false);
					}
				})
				.finally(() => setIsUpdating(false));
		}
	};
	useEffect(() => {
		if (latLong.lat !== '' && latLong.long !== '') {
			setCityTimeZone(tzlookup(latLong.lat, latLong.long));
		}
	}, [latLong]);

	return (
		<FuseAnimate animation="transition.slideLeftIn" duration={600}>
			<div className="add-company-cont mx-auto">
				<div className="flex  justify-between">
					<div className="flex gap-10">
						<Button
							onClick={() => {
								history.goBack();
							}}
						>
							<img
								alt="Go Back"
								className="cursor-pointer"
								src="assets/images/arrow-long.png"
								style={{ width: '25px' }}
							/>
						</Button>
						<h2 className="font-bold">Edit Company Details</h2>
					</div>
					{/* <div className="flex" style={{ marginRight: 15 }}>
						{!isEditing ? (
							<span className="mx-4">
								<CustomButton
									variant="secondary"
									height="46"
									width="100px"
									fontSize="15px"
									onClick={() => {
										setIsEditing(true);
									}}
								>
									<FontAwesomeIcon icon={faEdit} />
									<span> Edit </span>
								</CustomButton>
							</span>
						) : (
							''
						)}
					</div> */}
				</div>

				<div className="bg-white rounded update-company-form-cont">
					{isLoading ? (
						<div className="flex align-center justify-center">
							<CircularProgress />
						</div>
					) : (
						<>
							<div className="grid grid-cols-4 mt-32" style={{ gap: 20 }}>
								<div
									className="row-span-2 camera-holder"
									style={{ justifySelf: 'center' }}
									onClick={() => inputRef.current.click()}
								>
									<Avatar src={preview} style={{ width: 120, height: 120 }} />
									<div className="company-pp-overlay">
										<i className="fa fa-2x fa-camera" />
									</div>
									<input
										onChange={onSelectFile}
										type="file"
										name="file"
										id="image"
										className="hidden"
										ref={inputRef}
									/>
								</div>
								<TextField
									value={form?.name || ''}
									onChange={handleChange}
									name="name"
									className="w-5/6"
									label="Name of Company"
								/>
								<TextField
									disabled
									value={form.email || ''}
									onChange={handleChange}
									name="email"
									className="w-5/6"
									label="Email Address"
								/>
								<TextField
									value={form?.phone || ''}
									onChange={handleChange}
									name="phone"
									className="w-5/6"
									label="Contact Number"
								/>
								<TextField
									name="total_no_of_school"
									disabled
									value={form?.branches_count || ''}
									className="w-5/6"
									label="Total No of Schools"
								/>
								<TextField
									name="total_active_subscribers"
									value={form?.total_subscribers || ''}
									disabled
									className="w-5/6"
									label="Total Active Subscribers"
								/>
								<TextField
									value={form?.address || ''}
									onChange={handleChange}
									className="w-5/6"
									name="address"
									label="Address 1"
								/>
								<div />
								<TextField
									value={form?.address2 || ''}
									onChange={handleChange}
									className="w-5/6"
									name="address2"
									label="Address 2"
								/>
								<FormControl error={!!errTxts.country_code?.length} className="w-5/6">
									<InputLabel id="country_code">Country</InputLabel>
									<Select
										error={!!errTxts.country_code?.length}
										value={form.country_code}
										onChange={handleChange}
										name="country_code"
										id="country_code"
									>
										{countries.length ? (
											countries.map((country) => {
												return (
													<MenuItem key={country.id} value={country.code}>
														{country.name}
													</MenuItem>
												);
											})
										) : (
											<MenuItem>Loading...</MenuItem>
										)}
									</Select>
									{errTxts.country_code && <FormHelperText>{errTxts.country_code}</FormHelperText>}
								</FormControl>
								{/* <FormControl error={!!errTxts.state_id?.length} className="w-5/6">
									<InputLabel id="state_id">State</InputLabel>
									<Select
										error={!!errTxts.state_id?.length}
										helperText={errTxts.state_id}
										value={form.state_id}
										name="state_id"
										onChange={handleChange}
										id="state_id"
									>
										{states.length ? (
											states.map(state => {
												return (
													<MenuItem key={state.id} value={state.id}>
														{state.name}
													</MenuItem>
												);
											})
										) : (
											<MenuItem>Loading...</MenuItem>
										)}
									</Select>
									{errTxts.state_id && <FormHelperText>{errTxts.state_id}</FormHelperText>}
								</FormControl>
								<div />
								<FormControl error={!!errTxts.city?.length} className="w-5/6">
									<InputLabel id="city">City</InputLabel>
									<Select
										name="city"
										value={form.city}
										onChange={handleChange}
										labelId="city"
										id="city"
										error={!!errTxts.city?.length}
										helperText={errTxts.city}
									>
										{city.length ? (
											city.map(city => {
												return (
													<MenuItem key={city.id} value={city.name}>
														<span id={city.id}>{city.name}</span>
													</MenuItem>
												);
											})
										) : (
											<MenuItem>Loading...</MenuItem>
										)}
									</Select>
									{errTxts.city && <FormHelperText>{errTxts.city}</FormHelperText>}
								</FormControl> */}
								<Autocomplete
									id="state-autocomplete"
									name="state_id"
									options={states}
									renderOption={(option) => (
										<>
											<div className="flex" style={{ gap: 10 }}>
												<div>{option.name}</div>
											</div>
										</>
									)}
									getOptionLabel={(option) => option.name}
									autoComplete={false}
									clearOnBlur={false}
									disableClearable
									loading={isStateloading}
									loadingText="...Loading"
									sx={{ width: '100%' }}
									onChange={(_e, v) => {
										setDefaultState(v);
										setForm({ ...form, state_id: v?.id || '' });
									}}
									onInputChange={(e, value) => {
										setStateSearchQuery(value);
										if (value === '') {
											setForm({ ...form, state_id: '', city: '' });
											setDefaultCity({ ...defaultState, name: '' });
										}
									}}
									getOptionSelected={(option, value) => {
										if (option.id === value?.id) {
											return true;
										}
										if (
											defaultState?.id &&
											states.length &&
											!states.find((state) => state.id === defaultState.id)
										) {
											// fix for default state not found in states list while still loading
											return true;
										}
										return false;
									}}
									value={defaultState}
									renderInput={(params) => (
										<TextField
											{...params}
											label="State"
											className="w-5/6"
											error={!!errTxts.state_id?.length}
											helperText={errTxts.state_id}
											autoComplete="off"
										/>
									)}
								/>
								<div />
								<Autocomplete
									id="city-autocomplete"
									name="city"
									options={city}
									renderOption={(option) => (
										<>
											<div className="flex" style={{ gap: 10 }}>
												<div>{option.name}</div>
											</div>
										</>
									)}
									getOptionLabel={(option) => option.name}
									autoComplete={false}
									clearOnBlur={false}
									disableClearable
									loading={isCityLoading}
									loadingText="...Loading"
									sx={{ width: '100%' }}
									onChange={(_e, v) => {
										setDefaultCity(v);
										const value = JSON.parse(v?.meta);
										setLatLong({
											...latLong,
											lat: value.latitude,
											long: value.longitude,
										});
										setForm({ ...form, city: v?.name });
									}}
									getOptionSelected={(option, value) => {
										if (option.id === value?.id) {
											return true;
										}
										if (
											defaultCity?.name &&
											city.length &&
											!city.find((c) => c.name === defaultCity.name)
										) {
											// fix for default city not found in cities list while still loading
											return true;
										}
										return false;
									}}
									value={defaultCity}
									renderInput={(params) => (
										<TextField
											{...params}
											label="City"
											className="w-5/6"
											error={!!errTxts.city?.length}
											helperText={errTxts.city}
											autoComplete="off"
											onChange={(e) => setSearchCityQuery(e.target.value)}
											value={defaultCity}
										/>
									)}
								/>
								<TextField
									value={form?.zip_code}
									onChange={handleChange}
									className="w-5/6"
									name="zip_code"
									label="Zipcode"
								/>
								<FormControl className="w-5/6">
									<InputLabel id="status">Status</InputLabel>
									<Select
										error={!!errTxts.status?.length}
										name="status"
										value={form?.status}
										onChange={handleChange}
										labelId="status"
										id="status"
										placeholder="Status"
									>
										<MenuItem selected value={1}>
											<span id="active">Active</span>
										</MenuItem>
										<MenuItem value={0}>
											<span id="disable">Inactive</span>
										</MenuItem>
									</Select>
								</FormControl>
							</div>
							<div className="flex justify-center w-max mt-40" style={{ gap: '20px' }}>
								{isUpdating ? (
									<div className="flex justify-center">
										<CircularProgress className="mx-auto" />
									</div>
								) : (
									<>
										<CustomButton
											variant="secondary"
											width={140}
											onClick={() => {
												history.goBack();
											}}
										>
											Cancel
										</CustomButton>
										<CustomButton
											variant="primary"
											width={140}
											onClick={() => {
												handleSubmit();
											}}
										>
											Update
										</CustomButton>
									</>
								)}
							</div>
						</>
					)}
				</div>
			</div>
		</FuseAnimate>
	);
}

export default EditCompany;
