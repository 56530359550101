/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import history from '@history';
import {
	CircularProgress,
	Avatar,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormHelperText,
	Button,
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Paper,
} from '@material-ui/core';
import {
	getCompany,
	getStateListByCountry,
	getCountryList,
	getCityList,
	getCompSchoolList,
} from 'app/services/Company/companyService';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import * as Actions from 'app/store/actions';
import FuseAnimate from '@fuse/core/FuseAnimate';
import CustomButton from 'app/customComponents/CustomButton/CustomButton';
import { useDispatch } from 'react-redux';
import './Company.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import CustomCheckbox from 'app/customComponents/CustomCheckbox/CustomCheckbox';
import InfiniteScroll from 'react-infinite-scroll-component';
import { makeStyles } from '@material-ui/core/styles';
import DisableSchooConfirmDialog from './DisableSchooConfirmDialog';
import { exportSchoolData } from 'app/services/reports/reports';
import Input from '@material-ui/core/Input';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		display: 'flex',
		position: 'fixed',
		width: '100%',
		height: '100%',
		flex: '1 1 auto',
	},
}));
function ViewCompany() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [form, setForm] = useState({ status: 1 });
	const [errTxts, setErrTxts] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [countries, setCountries] = useState([]);
	const [states, setStates] = useState([]);
	const [timeZone, setTimeZone] = useState({ city: '', state: '' });
	const { id } = useParams();
	const [selectedFile, setSelectedFile] = useState(null);
	const [preview, setPreview] = useState(null);
	const inputRef = useRef(null);
	const [rows, setRows] = useState([]);
	const [firstLoad, setFirstLoad] = useState(true);
	const [refresh, setRefresh] = useState(false);
	const [page, setPage] = useState(1);
	const [hasMore, setHasMore] = useState(false);
	const [fetchingMore, setFetchingMore] = useState(false);
	const [isSchoolLoading, setIsSchoolLoading] = useState(false);
	const [exportLoaders, setExportLoaders] = useState([]);

	const handleChange = (ev) => {
		const { name, value } = ev.target;
		setErrTxts({ ...errTxts, [name]: [] });
		if (name == 'city') {
			setTimeZone({ ...timeZone, city: value });
		}
		if (name == 'state') {
			setTimeZone({ ...timeZone, state: value });
		}
		if (name === 'country_code') {
			setErrTxts({ ...errTxts, country_code: '' });
			setForm({ ...form, country_code: value, state_id: '', city: '' });
			return;
		}
		if (name === 'state_id') {
			setErrTxts({ ...errTxts, state_id: '' });
			setForm({ ...form, state_id: value, city: '' });
			return;
		}
		setForm({ ...form, [name]: value });
	};
	const gotoEdit = (row) => {
		history.push({
			pathname: `/SchoolDetail/${row.id}`,
			state: {
				row,
			},
		});
	};
	useEffect(() => {
		let isMounted = true;
		setIsLoading(true);
		getCompany(id)
			.then((res) => {
				if (!isMounted) return;
				setForm(res.data);
				setIsLoading(false);
			})
			.catch((err) => {
				if (!isMounted) return;
				dispatch(
					Actions.showMessage({
						message: 'Failed to get company details.',
						autoHideDuration: 1500,
						variant: 'error',
					}),
				);
				setIsLoading(false);
			});
		return () => {
			isMounted = false;
		};
	}, [id]);

	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(
			() => {
				if (!isMounted) return;
				setIsSchoolLoading(true);
				getCompSchoolList(id, 1)
					.then((res) => {
						if (!isMounted) return;
						setFirstLoad(false);
						setRows(res.data.data || []);
						setExportLoaders(res.data.data.map(() => false));
						setHasMore(res.data.to < res.data.total);
						setPage(res.data.current_page + 1);
					})
					.catch((err) => {
						if (!isMounted) return;
						dispatch(
							Actions.showMessage({
								message: 'Failed to fetch data, please refresh',
								variant: 'error',
							}),
						);
					})
					.finally(() => {
						if (!isMounted) return;
						setIsSchoolLoading(false);
					});
			},
			firstLoad ? 0 : 1000,
		);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
		// eslint-disable-next-line
	}, [refresh]);

	const handleLoadMore = () => {
		setFetchingMore(true);
		getCompSchoolList(id, page)
			.then((res) => {
				if (res.data.last_page > res.data.current_page) {
					setHasMore(true);
				} else {
					setHasMore(false);
				}
				setPage(res.data.current_page + 1);
				setRows(rows.concat(res.data.data));
				setExportLoaders(exportLoaders.concat(res.data.data.map(() => false)));
				setFetchingMore(false);
			})
			.catch((err) => {
				setFetchingMore(false);
				dispatch(
					Actions.showMessage({
						message: 'Failed to fetch data, please refresh',
						variant: 'error',
					}),
				);
			});
	};

	useEffect(() => {
		if (!selectedFile) {
			setPreview(form?.logo || null);
			return;
		}

		const objectUrl = URL.createObjectURL(selectedFile);
		setPreview(objectUrl);

		return () => URL.revokeObjectURL(objectUrl);
	}, [selectedFile, form?.logo]);

	const onSelectFile = (e) => {
		if (!e.target.files || e.target.files.length === 0) {
			setSelectedFile(form?.logo || null);
			return;
		}

		setSelectedFile(e.target.files[0]);
	};

	const handleDisable = (ev, row) => {
		ev.preventDefault();
		dispatch(
			Actions.openDialog({
				children: <DisableSchooConfirmDialog row={row} setRefresh={setRefresh} refresh={refresh} />,
			}),
		);
	};

	useEffect(() => {
		let isMounted = true;
		if (form.country_code) {
			getStateListByCountry(form.country_code)
				.then((res) => {
					if (!isMounted) return;
					setStates(res.data);
				})
				.catch((err) => {
					if (!isMounted) return;
					dispatch(
						Actions.showMessage({
							message: 'Failed to get states.',
							autoHideDuration: 1500,
							variant: 'error',
						}),
					);
				});
		}
		return () => {
			isMounted = false;
		};
	}, [form.country_code]);

	useEffect(() => {
		let isMounted = true;
		getCountryList()
			.then((res) => {
				if (!isMounted) return;
				setCountries(res.data);
			})
			.catch((err) => {
				if (!isMounted) return;
				dispatch(
					Actions.showMessage({
						message: 'Failed to get countries.',
						autoHideDuration: 1500,
						variant: 'error',
					}),
				);
			});
		return () => {
			isMounted = false;
		};
	}, []);

	const exportCompany = (school, index) => {
		setExportLoaders(exportLoaders.map((item, i) => (i === index ? true : item)));
		exportSchoolData(school.id)
			.then((res) => {
				const link = document.createElement('a');
				link.href = res.data.downloadable_file;
				link.setAttribute('download', `${school.name}-data-export.zip`);
				document.body.appendChild(link);
				link.click();
				link.parentNode.removeChild(link);
			})
			.catch((err) => {
				dispatch(
					Actions.showMessage({
						message: 'Failed to export school data.',
						variant: 'error',
					}),
				);
			})
			.finally(() => {
				setExportLoaders(exportLoaders.map((item, i) => (i === index ? false : item)));
			});
	};

	return (
		<FuseAnimate animation="transition.slideLeftIn" duration={600}>
			<FuseScrollbars className={classes.content} scrollToTopOnRouteChange>
				<div className="view-company-cont mx-auto">
					<div className="flex justify-between">
						<div className="flex gap-10">
							<Button
								onClick={() => {
									history.push({
										pathname: '/Company',
									});
								}}
							>
								<img
									alt="Go Back"
									className="cursor-pointer"
									src="assets/images/arrow-long.png"
									style={{ width: '25px' }}
								/>
							</Button>
							<h2 className="font-bold" style={{ fontSize: '20px' }}>
								Company Details
							</h2>
						</div>
						<div className="flex" style={{ marginRight: 15 }}>
							<span className="mx-4">
								<CustomButton
									variant="secondary"
									height="46"
									width="100px"
									fontSize="15px"
									onClick={() => history.push({ pathname: `/EditCompany/${id}` })}
								>
									<FontAwesomeIcon icon={faEdit} />
									<span> Edit </span>
								</CustomButton>
							</span>
						</div>
					</div>

					<div className="bg-white rounded update-company-form-cont">
						{isLoading ? (
							<div className="flex align-center justify-center">
								<CircularProgress />
							</div>
						) : (
							<div className="grid grid-cols-4 mt-32" style={{ gap: 20 }}>
								<div
									className="row-span-2 camera-holder"
									style={{ justifySelf: 'center' }}
									onClick={() => inputRef.current.click()}
								>
									<Avatar src={preview} style={{ width: 120, height: 120 }} />
									<input
										onChange={onSelectFile}
										type="file"
										name="file"
										id="image"
										className="hidden"
										ref={inputRef}
										disabled
									/>
								</div>
								<TextField
									disabled
									value={form?.name || ''}
									onChange={handleChange}
									name="name"
									className="w-5/6 disabled-css"
									label="Name of Company"
								/>
								<TextField
									disabled
									value={form?.admins[0]?.email || ''}
									onChange={handleChange}
									name="email"
									className="w-5/6 disabled-css"
									label="Email Address"
								/>
								<TextField
									disabled
									value={form?.phone || ''}
									onChange={handleChange}
									name="phone"
									className="w-5/6 disabled-css"
									label="Contact Number"
								/>
								<TextField
									name="total_no_of_school"
									disabled
									value={form?.branches_count || ''}
									className="w-5/6 disabled-css"
									label="Total No of Schools"
								/>
								<TextField
									name="total_active_subscribers"
									value={form?.total_subscribers || ''}
									disabled
									className="w-5/6 disabled-css"
									label="Total Active Subscribers"
								/>
								<TextField
									disabled
									value={form?.address || ''}
									onChange={handleChange}
									className="w-5/6 disabled-css"
									name="address"
									label="Address 1"
								/>
								<div />
								<TextField
									disabled
									value={form?.address2 || ''}
									onChange={handleChange}
									className="w-5/6 disabled-css"
									name="address2"
									label="Address 2"
								/>
								<FormControl error={!!errTxts.country_code?.length} className="w-5/6 disabled-css">
									<InputLabel id="country_code">Country</InputLabel>
									<Select
										disabled
										error={!!errTxts.country_code?.length}
										value={form.country_code || ''}
										onChange={handleChange}
										name="country_code"
										id="country_code"
									>
										{countries.length ? (
											countries.map((country) => {
												return (
													<MenuItem key={country.id} value={country.code}>
														{country.name}
													</MenuItem>
												);
											})
										) : (
											<MenuItem>Loading...</MenuItem>
										)}
									</Select>
									{errTxts.country_code && <FormHelperText>{errTxts.country_code}</FormHelperText>}
								</FormControl>
								<FormControl error={!!errTxts.state_id?.length} className="w-5/6 disabled-css">
									<InputLabel id="state_id">State</InputLabel>
									{states.length ? (
										<Select
											disabled
											error={!!errTxts.state_id?.length}
											value={form.state_id}
											name="state_id"
											onChange={handleChange}
											id="state_id"
										>
											{states.map((state) => {
												return (
													<MenuItem key={state.id} value={state.id}>
														{state.name}
													</MenuItem>
												);
											})}
										</Select>
									) : (
										<Input disabled value={'Loading...'} className="w-5/6 disabled-css" />
									)}

									{errTxts.state_id && <FormHelperText>{errTxts.state_id}</FormHelperText>}
								</FormControl>
								<div />
								<FormControl error={!!errTxts.city?.length} className="w-5/6 disabled-css">
									<InputLabel id="city">City</InputLabel>
									<Select
										name="city"
										value={form.city}
										onChange={handleChange}
										labelId="city"
										id="city"
										error={!!errTxts.city?.length}
										disabled
									>
										<MenuItem value={form.city}>
											<span>{form.city}</span>
										</MenuItem>
									</Select>
									{errTxts.city && <FormHelperText>{errTxts.city}</FormHelperText>}
								</FormControl>
								<TextField
									disabled
									value={form?.zip_code}
									onChange={handleChange}
									className="w-5/6 disabled-css"
									name="zip_code"
									label="Zipcode"
								/>
								<FormControl className="w-5/6 disabled-css">
									<InputLabel id="status">Status</InputLabel>
									<Select
										disabled
										error={!!errTxts.status?.length}
										name="status"
										value={form?.status}
										onChange={handleChange}
										labelId="status"
										id="status"
										placeholder="Status"
									>
										<MenuItem selected value={1}>
											<span id="active">Active</span>
										</MenuItem>
										<MenuItem value={0}>
											<span id="disable">Inactive</span>
										</MenuItem>
									</Select>
									{errTxts.status && <FormHelperText>{errTxts.status}</FormHelperText>}
								</FormControl>
							</div>
						)}
						<div className="flex  justify-between school-listing-div">
							<div className="flex gap-10">
								<h2 className="font-bold" style={{ fontSize: '20px' }}>
									Schools
								</h2>
							</div>
							<div className="flex">
								<span className="mx-4">
									<CustomButton
										variant="secondary"
										height="46"
										width="100px"
										fontSize="15px"
										onClick={() => history.push({ pathname: `/addSchool/${id}` })}
									>
										+ Add
									</CustomButton>
								</span>
							</div>
						</div>
						<TableContainer id="Scrollable-table" component={Paper} className="company-school-table">
							<Table stickyHeader className="company-table">
								<TableHead>
									<TableRow>
										<TableCell className="bg-white companyTableHeader" style={{ width: '19%' }}>
											School Name
										</TableCell>
										<TableCell className="bg-white companyTableHeader" style={{ width: '15%' }}>
											Active Subscribers
										</TableCell>
										<TableCell className="bg-white companyTableHeader" style={{ width: '15%' }}>
											Email Address
										</TableCell>
										<TableCell className="bg-white companyTableHeader" style={{ width: '10%' }}>
											Contact No.
										</TableCell>
										<TableCell className="bg-white companyTableHeader" style={{ width: '10%' }}>
											Status
										</TableCell>
										<TableCell className="bg-white companyTableHeader" style={{ width: '20%' }}>
											Action
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody className="">
									{isSchoolLoading ? (
										<TableRow>
											<TableCell align="center" colSpan={8}>
												<CircularProgress />
											</TableCell>
										</TableRow>
									) : !rows.length && !firstLoad ? (
										<TableRow>
											<TableCell align="center" colSpan={8}>
												No Schools
											</TableCell>
										</TableRow>
									) : (
										rows?.map((row, index) => (
											<TableRow key={row.id}>
												<TableCell style={{ fontWeight: 700 }} component="th" scope="row">
													<div className="flex items-center">
														<Avatar className="mr-6" alt="company-face" src={row?.logo} />
														<div style={{ fontSize: 12 }}>{row?.name}</div>
													</div>
												</TableCell>
												<TableCell align="left">
													<div style={{ fontSize: 12 }}>{row?.total_subscribers}</div>
												</TableCell>
												<TableCell align="left">
													<div style={{ fontSize: 12 }}>{row?.email}</div>
												</TableCell>
												<TableCell align="left">
													<div style={{ fontSize: 12 }}>{row?.phone}</div>
												</TableCell>
												<TableCell>
													<div
														style={{
															fontWeight: 'bold',
															fontSize: 12,
															color: `${row?.status === 1 ? '#04C01C' : '#FF4B4B'}`,
														}}
													>
														{row?.status === 1 ? 'Active' : 'Inactive'}
													</div>
												</TableCell>
												<TableCell className="action-cell">
													<span
														id={`view-details-${index}`}
														className="view-btn-wrapper"
														onClick={() => {
															gotoEdit(row);
														}}
													>
														<button type="button" style={{ fontSize: 11 }}>
															View Details
														</button>
													</span>
													{exportLoaders[index] ? (
														<CircularProgress size={20} />
													) : (
														<img
															onClick={() => exportCompany(row, index)}
															src="assets/images/export.svg"
															width="30px"
															alt="export"
															className="cursor-pointer"
														/>
													)}
													<CustomCheckbox row={row} onClick={handleDisable} id={index} />
												</TableCell>
											</TableRow>
										))
									)}
									{fetchingMore ? (
										<TableRow>
											<TableCell align="center" colSpan={8}>
												<CircularProgress />
											</TableCell>
										</TableRow>
									) : (
										<></>
									)}
								</TableBody>
							</Table>
						</TableContainer>
						<InfiniteScroll
							dataLength={rows.length}
							next={handleLoadMore}
							hasMore={hasMore}
							scrollableTarget="Scrollable-table"
						/>
					</div>
				</div>
			</FuseScrollbars>
		</FuseAnimate>
	);
}

export default ViewCompany;
