import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getNotifications } from 'app/services/notifications/notifications';
import Notifications from './productOwnerNotifications';
import './productOwnerTopNav.css';

const useStyles = makeStyles((_) => ({
	separator: {
		marginTop: 15,
		width: 1,
		height: 60,
		backgroundColor: 'rgba(0, 0, 0, 0.12)',
	},
}));

function ProductOwnerTopNav() {
	const classes = useStyles();

	const [notifications, setNotifications] = React.useState([]);
	const [loadingNotifications, setLoadingNotifications] = React.useState(false);
	const [hasMore, setHasMore] = React.useState(true);
	const [page, setPage] = React.useState(1);
	const [fetchingMore, setFetchingMore] = React.useState(false);
	const [unreadCount, setUnreadCount] = React.useState(0);

	useEffect(() => {
		let isMounted = true;
		setLoadingNotifications(true);
		setTimeout(() => {
			if (!isMounted) return;
			getNotificationsPage1(isMounted);
		}, 5000);

		navigator.serviceWorker.addEventListener('message', handleReceiveNotification);

		return () => {
			isMounted = false;
			navigator.serviceWorker.removeEventListener('message', handleReceiveNotification);
		};
	}, []);

	const getNotificationsPage1 = (isMounted) => {
		getNotifications(1)
			.then((res) => {
				if (!isMounted) return;
				setNotifications(res.data.data);
				setUnreadCount(res.data.unread_notifications_count);
				if (res.data.last_page > res.data.current_page) {
					setHasMore(true);
					setPage(page + 1);
				} else {
					setHasMore(false);
				}
			})
			.catch((err) => console.log(err))
			.finally(() => {
				if (!isMounted) return;
				setLoadingNotifications(false);
			});
	};

	const handleReceiveNotification = (e) => {
		console.log(e);
		const newNotification = {
			// id: e?.data?.data?.id,
			created_at: new Date(),
			data: {
				title: e?.data?.notification?.title,
				body: e?.data?.notification?.body,
				thumb: e?.data?.data?.thumb,
			},
		};
		setNotifications((prevState) => [newNotification, ...prevState]);
		setUnreadCount((prevState) => prevState + 1);
	};

	const handleLoadMore = () => {
		setFetchingMore(true);
		getNotifications(page)
			.then((res) => {
				setNotifications([...notifications, ...res.data.data]);
				if (res.data.last_page > res.data.current_page) {
					setHasMore(true);
					setPage(page + 1);
				} else {
					setHasMore(false);
				}
			})
			.catch((err) => {
				console.log({ ...err });
				setHasMore(false);
			})
			.finally(() => setFetchingMore(false));
	};

	return (
		<div className="flex justify-between items-center">
			<div className="icons-wrapper flex">
				<div className="icon-wrapper">
					<Notifications
						hasMore={hasMore}
						fetchingMore={fetchingMore}
						notifications={notifications}
						loadingNotifications={loadingNotifications}
						handleLoadMore={handleLoadMore}
						setNotifications={setNotifications}
						setUnreadCount={setUnreadCount}
					/>
					{unreadCount ? <span className="notification-count">{unreadCount}</span> : ''}
				</div>
			</div>
			<div className={classes.separator} />
		</div>
	);
}

export default ProductOwnerTopNav;
