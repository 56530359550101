import React from 'react';
import './map.css';

const Map = ({ schools = [], zoomLevel }) => {
	const ref = React.useRef(null);
	const [map, setMap] = React.useState();
	const [activeMarker, setActiveMarker] = React.useState();

	React.useEffect(() => {
		if (ref.current && !map) {
			setMap(
				new window.google.maps.Map(ref.current, {
					center: {
						lat: schools[0]?.location.latitude || 39.8097343,
						lng: schools[0]?.location.longitude || -98.5556199,
					},
					zoom: zoomLevel,
					mapId: 'PDL2_PRODUCT_OWNER',
				}),
			);
		}
	}, [ref, map]);
	return (
		<div ref={ref} className="google-map">
			{schools.map((school, index) => (
				<Marker
					key={school.id}
					position={{
						lat: school.location.lat || school.location.latitude,
						lng: school.location.lng || school.location.longitude,
					}}
					map={map}
					school={school}
					icon={activeMarker === index ? '/assets/images/blue-loc.svg' : '/assets/images/gray-loc.svg'}
					activeIndex={activeMarker}
					setActiveMarker={setActiveMarker}
					index={index}
				/>
			))}
		</div>
	);
};

export default Map;

const Marker = (options) => {
	const [marker, setMarker] = React.useState();
	const [infoWindow, setInfoWindow] = React.useState();
	let listener;
	let listener2;

	const content =
		"<div class='p-8 flex gap-12'>" +
		'<div>' +
		`<img style="border-radius: 50%; height: 40px; width: 40px;" src=${
			options.school.logo || 'assets/images/school.png'
		} alt='' />` +
		'</div>' +
		"<div class='flex flex-col gap-6'>" +
		`<div class="text-base font-semibold">${options.school.name}</div>` +
		`<div>${options.school.address}</div>` +
		`<div>${options.school.admins ? options.school.admins[0]?.email : ''}</div>` +
		`<div>${options.school.admins ? options.school.admins[0]?.phone : ''}</div>` +
		'</div>' +
		'</div>';

	React.useEffect(() => {
		if (!marker) {
			let icon = document.createElement('img');
			icon.src = options.active ? '/assets/images/blue-loc.svg' : '/assets/images/gray-loc.svg';
			setMarker(
				new window.google.maps.marker.AdvancedMarkerElement({
					position: options.position,
					map: options.map,
					content: icon,
				}),
			);
		}
		if (!infoWindow) {
			setInfoWindow(
				new window.google.maps.InfoWindow({
					content,
				}),
			);
		}
		if (marker && infoWindow) {
			listener2 = infoWindow.addListener('closeclick', (event) => {
				const icon = document.createElement('img');
				icon.src = '/assets/images/gray-loc.svg';
				marker.content = icon;
				options.setActiveMarker(null);
			});
			listener = marker.addListener('click', () => {
				const icon = document.createElement('img');
				if (options.activeIndex !== options.index) {
					icon.src = '/assets/images/blue-loc.svg';
					marker.content = icon;
					options.setActiveMarker(options.index);
					infoWindow.open(options.map, marker);
					return;
				}

				icon.src = '/assets/images/gray-loc.svg';
				marker.content = icon;
				options.setActiveMarker(null);
				infoWindow.close();
			});
			if (options.activeIndex !== options.index) {
				infoWindow.close();
			}
		}

		// remove marker from map on unmount
		return () => {
			window.google.maps.event.removeListener(listener);
			window.google.maps.event.removeListener(listener2);
			if (marker) {
				// marker.setMap(null);
			}
		};
	}, [marker, infoWindow, options]);

	return null;
};
