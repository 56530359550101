import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';
import authRoles from '../auth/authRoles';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
	{
		id: 'Dashboard',
		title: 'Dashboard',
		translate: 'Dashboard',
		type: 'item',
		isCustomIcon: true,
		icon: 'dashboard.png',
		url: '/productOwner',
		auth: authRoles.productOwner,
	},
	{
		id: 'Company',
		title: 'Company',
		translate: 'Company',
		type: 'item',
		isCustomIcon: true,
		icon: 'company.png',
		url: '/Company',
		auth: authRoles.productOwner,
	},
	{
		id: 'Packages',
		title: 'Packages',
		translate: 'Packages',
		type: 'item',
		isCustomIcon: true,
		icon: 'packages.png',
		url: '/Packages',
		auth: authRoles.productOwner,
	},
	{
		id: 'userManagement',
		title: 'User Management',
		translate: 'User Management',
		type: 'item',
		isCustomIcon: true,
		icon: 'user-management.png',
		url: '/UserManagement',
		auth: authRoles.productOwner,
	},
	{
		id: 'Notifications',
		title: 'Notifications',
		translate: 'Notifications',
		type: 'item',
		isCustomIcon: true,
		icon: 'notification.png',
		url: '/Notifications',
		auth: authRoles.productOwner,
	},
	{
		id: 'Reports',
		title: 'Reports',
		translate: 'Reports',
		type: 'item',
		isCustomIcon: true,
		icon: 'report.png',
		url: '/Reports',
		auth: authRoles.productOwner,
	},
	{
		id: 'MapView',
		title: 'Map View',
		translate: 'Map View',
		type: 'item',
		isCustomIcon: true,
		icon: 'Map-icon.png',
		url: '/MapView',
		auth: authRoles.productOwner,
	},
	{
		id: 'PaymentManagement',
		title: 'Payment Management',
		translate: 'Payment Management',
		type: 'item',
		isCustomIcon: true,
		icon: 'payment-management.png',
		url: '/PaymentManagement',
		auth: authRoles.productOwner,
	},
	{
		id: 'Finance',
		title: 'Finance',
		translate: 'Finance',
		type: 'item',
		isCustomIcon: true,
		icon: 'finance-icon.png',
		url: '/finance',
		auth: authRoles.productOwner,
	},
];

export default navigationConfig;
