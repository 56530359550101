import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

const getAgeDetails = (oldDate, newDate) => {
	if (oldDate.isValid()) {
		const years = newDate.diff(oldDate, 'year');
		const allMonths = newDate.diff(oldDate, 'month');
		const months = newDate.diff(oldDate, 'month') - years * 12;
		const days = newDate.diff(oldDate.add(years, 'year').add(months, 'month'), 'day');
		return {
			years,
			months,
			allMonths,
			days,
			allDays: newDate.diff(oldDate, 'day'),
		};
	} else {
		return null;
	}
};

const generateAgeString = (dob) => {
	const details = getAgeDetails(dayjs(dob), dayjs());
	if (details) {
		return `${details.years !== 0 ? `${details.years} year` : ''}${details.years > 1 ? 's' : ''}${
			details.months !== 0 ? ` ${details.months} month` : ''
		}${details.months > 1 ? 's' : ''}${details.days !== 0 ? ` ${details.days} day` : ''}${
			details.days > 1 ? 's' : ''
		}`;
	} else {
		return '-';
	}
};

const scrollIntoView = (id) => {
	const element = document.getElementById(id);
	if (element) {
		element.scrollIntoView();
	}
};

const getImageUrl = (file) => {
	const file_Name = file.name;
	const fileExtension = file_Name.split('.').pop();
	const uuid = uuidv4();
	const newFileName = `${uuid}.${fileExtension}`;
	const filename = `web/public/profile_images/${newFileName}`;
	return filename;
};

const isSameIPVersion = (ip1, ip2) => {
	// Regular expressions to match IPv4 and IPv6 patterns
	const ipv4Pattern = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
	const ipv6Pattern = /^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/;

	function isIPv4(address) {
		return ipv4Pattern.test(address);
	}

	function isIPv6(address) {
		return ipv6Pattern.test(address);
	}

	if (isIPv4(ip1) && isIPv4(ip2)) {
		return true; // Both IPs are IPv4
	} else if (isIPv6(ip1) && isIPv6(ip2)) {
		return true; // Both IPs are IPv6
	} else {
		return false; // IPs are of different versions
	}
};

export { getAgeDetails, scrollIntoView, generateAgeString, getImageUrl, isSameIPVersion };
