import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import FuseUtils from '@fuse/utils';
import Icon from '@material-ui/core/Icon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import * as Actions from 'app/store/actions';
import clsx from 'clsx';
// import { upperCase } from 'lodash-es';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FuseNavBadge from '../FuseNavBadge';

const useStyles = makeStyles((theme) => ({
	item: (props) => ({
		height: 40,
		paddingRight: 12,
		margin: '20px 0',
		paddingLeft: props.itemPadding > 80 ? 80 : props.itemPadding,
		'&.active': {
			backgroundColor: '#1992F4',
			// color: `white`,
			// pointerEvents: 'none',
			transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
			'& .list-item-text-primary': {
				color: 'inherit',
			},
			'& .list-item-icon': {
				color: 'inherit',
			},
			'& .list-subheader-text': {
				fontSize: 15,
			},
		},

		'& .list-item-icon': {
			// marginRight: 15,
			marginLeft: 10,
		},
		'& .list-item-text-primary ': {
			fontSize: 18,
			textTransform: 'capitalize',
			fontWeight: '500',
		},
		color: theme.palette.text.primary,
		cursor: 'pointer',
		textDecoration: 'none!important',
	}),
}));

function FuseNavVerticalItem(props) {
	const dispatch = useDispatch();
	const userRole = useSelector(({ auth }) => auth.user.role);
	const { item, nestedLevel } = props;
	const classes = useStyles({
		itemPadding: nestedLevel > 0 ? 40 + nestedLevel * 16 : 24,
	});
	const { t } = useTranslation('navigation');

	if (!FuseUtils.hasPermission(item.auth, userRole)) {
		return null;
	}

	return (
		<ListItem
			button
			component={NavLinkAdapter}
			to={item.url}
			activeClassName="active"
			className={clsx(classes.item, 'list-item')}
			onClick={(ev) => dispatch(Actions.navbarCloseMobile())}
			exact={item.exact}
		>
			{!item.isCustomIcon && item.icon && <Icon className="list-item-icon flex-shrink-0">{item.icon}</Icon>}
			{item.isCustomIcon && item.icon && (
				<div style={{ width: 32, marginRight: 15 }}>
					<img
						className="list-item-icon flex-shrink-0"
						src={`assets/images/navbarIcons/${item.icon}`}
						alt=""
					/>
				</div>
			)}
			{/* <ListItemText
                    className="list-item-text"
                    primary={item.translate ? t(item.translate) : item.title}
                    classes={{ primary: 'text-8 list-item-text-primary' }}
                /> */}
			<div className="list-item-text text-8 list-item-text-primary">
				{item.translate ? (
					item.translate === 'Payment Management' ? (
						<div className="flex flex-col">
							<div>Payment</div>
							<div>Management</div>
						</div>
					) : (
						t(item.translate)
					)
				) : (
					item.title
				)}
			</div>
			{item.badge && <FuseNavBadge badge={item.badge} />}
		</ListItem>
	);
}

FuseNavVerticalItem.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string.isRequired,
		title: PropTypes.string,
		icon: PropTypes.string,
		url: PropTypes.string,
	}),
};

FuseNavVerticalItem.defaultProps = {};

const NavVerticalItem = withRouter(React.memo(FuseNavVerticalItem));

export default NavVerticalItem;
