/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import NavbarMobileToggleButton from 'app/fuse-layouts/shared-components/NavbarMobileToggleButton';
import UserMenu from 'app/fuse-layouts/shared-components/UserMenu';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
// import SchoolAdminTopNav from './schoolAdminTopNav/SchoolAdminTopNav';
import ProductOwnerTopNav from './productOwnerTopNav/productOwnerTopNav';
import history from '@history';

// import QuickPanelToggleButton from 'app/fuse-layouts/shared-components/quickPanel/QuickPanelToggleButton';

const useStyles = makeStyles((theme) => ({
	separator: {
		marginTop: 8,
		width: 1,
		height: 50,
		backgroundColor: theme.palette.divider,
	},
}));

function ToolbarLayout1(props) {
	const location = useLocation();
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const toolbarTheme = useSelector(({ fuse }) => fuse.settings.toolbarTheme);
	const user = useSelector(({ auth }) => auth.user);

	const classes = useStyles(props);

	return (
		<ThemeProvider theme={toolbarTheme}>
			<AppBar
				id="fuse-toolbar"
				className="flex relative z-10"
				color="default"
				style={{ backgroundColor: '#fff' }}
			>
				<Toolbar className="p-0">
					{config.navbar.display && config.navbar.position === 'left' && (
						<Hidden lgUp>
							<NavbarMobileToggleButton className="w-64 h-64 p-0" />
							<div className={classes.separator} />
						</Hidden>
					)}

					<div className="flex flex-1 ml-25" style={{ marginLeft: '35px' }}>
						{user.role[0] === 'product_owner' ? (
							<img
								className="cursor-pointer"
								src="assets/images/logos/logo22.png"
								alt=""
								width={138}
								style={{ height: '100%' }}
								onClick={() => history.push('/productOwner')}
							/>
						) : (
							<></>
						)}

						<div className={classes.separator} style={{ marginLeft: '86px' }} />
					</div>

					{!(location.pathname === '/student') &&
					!(location.pathname === '/editstudent') &&
					!(location.pathname === '/contact') ? (
						<div className="flex justify-between items-center">
							{/* {(user.role[0] === 'school_admin' || user.role[0] === 'super_school_admin') && (
								<SchoolAdminTopNav />
							)} */}
							{user.role[0] === 'product_owner' && <ProductOwnerTopNav />}

							{(user.role[0] === 'school_admin' || user.role[0] === 'super_school_admin') && (
								<div className={classes.separator} />
							)}

							{user.role.length ? <UserMenu /> : ''}
						</div>
					) : (
						''
					)}
				</Toolbar>
			</AppBar>
		</ThemeProvider>
	);
}

export default ToolbarLayout1;
