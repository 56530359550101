import React, { useState } from 'react';

import CompanyDetail from './CompanyDetail';
import SchoolRoyalties from './SchoolRoyalties';

function RoyaltyInfo() {
	const [activeId, setActiveId] = useState(null);
	const [company_id, setCompany_id] = useState(null);
	return (
		<>
			{activeId ? (
				<CompanyDetail activeId={activeId} setActiveId={setActiveId} />
			) : (
				<SchoolRoyalties setActiveId={setActiveId} setCompany_id={setCompany_id} />
			)}
		</>
	);
}

export default RoyaltyInfo;
