import React from 'react';
import authRoles from 'app/auth/authRoles';
import CompanyListing from './CompanyListing';
import SchoolDetail from './SchoolDetail';
import ViewCompany from './ViewCompany';
import AddCompany from './AddCompany';
import EditCompany from './EditCompany';
import AddSchool from './AddSchool';
import EditSchool from './EditSchool';

const CompanyConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: '/company',
			component: CompanyListing,
		},
		{
			path: '/schoolDetail/:id',
			component: SchoolDetail,
		},
		{
			path: '/viewCompany/:id',
			component: ViewCompany,
		},
		{
			path: '/addCompany',
			component: AddCompany,
		},
		{
			path: '/editCompany/:id',
			component: EditCompany,
		},
		{
			path: '/addSchool/:id',
			component: AddSchool,
		},
		{
			path: '/EditSchool/:id',
			component: EditSchool,
		},
	],
	auth: authRoles.productOwner,
};

export default CompanyConfig;
