/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import './Reports.css';
import FuseAnimate from '@fuse/core/FuseAnimate';
import history from '@history';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	content: {
		position: 'relative',
		display: 'flex',
		overflow: 'auto',
		flex: '1 1 auto',
		flexDirection: 'column',
		width: '100%',
		'-webkit-overflow-scrolling': 'touch',
		zIndex: 2,
	},
}));

const Checkinpage = () => {
	history.push({
		pathname: `/reports-Checkin`,
	});
};

function Reports() {
	const classes = useStyles();

	const goToSchoolMap = () => {
		history.push({
			pathname: `/school-map-report`,
		});
	};

	return (
		<FuseScrollbars className={classes.content} scrollToTopOnRouteChange>
			<FuseAnimate animation="transition.slideLeftIn" duration={600}>
				<div className="reports-main-div px-60 pt-60">
					<div className="mb-48">
						<h3 className="font-bold mb-28" style={{ fontSize: '20px' }}>
							Reporting{' '}
						</h3>
						<div className="flex flex-nowrap mb-28" style={{ gap: 50 }}>
							<div
								className="bg-white rounded room-reports-box cursor-pointer"
								onClick={() => history.push('/SchoolEnrollment')}
							>
								<h3>School Enrollment </h3>
								<p>Check out all the information of enrolled Companies/ Schools. </p>
							</div>
							<div
								className="bg-white rounded room-reports-box cursor-pointer"
								onClick={() => history.push('/ParentEnrollment')}
							>
								<h3>Parent Enrollment</h3>
								<p>Check out all the information of enrolled parents. </p>
							</div>
						</div>

						<div className="flex flex-nowrap mb-28" style={{ gap: 50 }}>
							<div
								className="bg-white rounded room-reports-box cursor-pointer"
								onClick={() => history.push('/RevenueReport')}
							>
								<h3>Revenue/ Subscription Report </h3>
								<p>Check out all the detailed information of the revenue. </p>
							</div>
							<div className="bg-white rounded room-reports-box cursor-pointer">
								<h3>Daily Users </h3>
								<p>Check out the daily perfect day user's information. </p>
							</div>
						</div>

						<div className="flex flex-nowrap mb-28" style={{ gap: 50 }}>
							<div className="bg-white rounded room-reports-box cursor-pointer">
								<h3>Daily Live Stream Users </h3>
								<p>Check out all the perfect day live streaming user’s information. </p>
							</div>
							<div className="bg-white rounded room-reports-box cursor-pointer">
								<h3>Daily Live Stream Usage </h3>
								<p>Check out all the daily information of the perfect day live stream usage. </p>
							</div>
						</div>

						{/* <div>
							<div className="flex flex-nowrap mb-28" style={{ gap: 50 }}>
								<div
									className="bg-white rounded download-reports-box cursor-pointer"
									onClick={goToSchoolMap}
								>
									<h3>School Enrollment Map View </h3>
									<p> Check out all the information of enrolled schools on map view. </p>
								</div>
								<div className="student-reports-box" />
							</div>
						</div> */}
					</div>
				</div>
			</FuseAnimate>
		</FuseScrollbars>
	);
}
export default Reports;
