/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import history from '@history';
import {
	CircularProgress,
	Avatar,
	TextField,
	Button,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	ListItemText,
	Checkbox,
	ListItem,
	ListItemIcon,
	List,
	Popover,
	FormHelperText,
} from '@material-ui/core';
import * as Actions from 'app/store/actions';
import FuseAnimate from '@fuse/core/FuseAnimate';
import CustomButton from 'app/customComponents/CustomButton/CustomButton';
import { useDispatch } from 'react-redux';
import './Packages.css';
import { useParams } from 'react-router-dom';
import { getPackageById, updatePackage } from 'app/services/Packages/packages';
import CustomCheckbox from 'app/customComponents/CustomCheckbox/CustomCheckbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
	getContentAnchorEl: null,
	anchorOrigin: {
		vertical: 'bottom',
		horizontal: 'center',
	},
	transformOrigin: {
		vertical: 'top',
		horizontal: 'center',
	},
	variant: 'menu',
};

function PackageEdit() {
	const dispatch = useDispatch();
	const [form, setForm] = useState({ features: [], active: '', duration: '' });
	const [errTxts, setErrTxts] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const { id } = useParams();
	const [features, setFeatures] = useState([]);
	const [selected, setSelected] = useState([]);
	const [submitLoading, setSubmitLoading] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleFeatureToggle = (value) => () => {
		const currentIndex = selected.indexOf(value);
		const newChecked = [...selected];

		// if value contains "Users", remove all other options from the selected array that also contain "Users"
		if (value.includes(') User')) {
			if (value.includes('6(Six) Users')) {
				setForm({ ...form, allowed_user_number: 6 });
			} else if (value.includes('4(Four) Users')) {
				setForm({ ...form, allowed_user_number: 4 });
			} else if (value.includes('2(Two) Users')) {
				setForm({ ...form, allowed_user_number: 2 });
			}
			// prevent the box from being unselected if it is the only item in the selected array that contains ") User"
			if (currentIndex !== -1 && newChecked.filter((item) => item.includes(') User')).length === 1) {
				return;
			}
			const filtered = newChecked.filter((item) => !item.includes(') User'));
			setSelected([...filtered, value]);
			return;
		}

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setSelected(newChecked);
	};

	const handleOpenFeaturesList = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseFeaturesList = () => {
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);
	const featureListPopoverId = open ? 'feature-popover' : undefined;

	const handleChange = (ev) => {
		const { name, value } = ev.target;
		// require at least 1 user or an empty string (which will get caught by error handling on submit)
		if (name === 'allowed_user_number') {
			if (value === '') {
				setForm({ ...form, allowed_user_number: '' });
			} else if (value <= 0) {
				setForm({ ...form, allowed_user_number: 1 });
			} else {
				setForm({ ...form, allowed_user_number: value });
			}
		} else {
			setForm({ ...form, [name]: value });
		}

		setErrTxts({ ...errTxts, [name]: [] });

		if (name === 'features') {
			setSelected(value);
		}
	};
	useEffect(() => {
		let isMounted = true;
		setIsLoading(true);
		getPackageById(id)
			.then((res) => {
				if (!isMounted) return;
				setForm(res?.data?.package);
				setFeatures(res?.data?.features_list.map((feature) => feature.title));
				setSelected(res?.data?.package?.features);
				setIsLoading(false);
			})
			.catch((err) => {
				if (!isMounted) return;
				dispatch(
					Actions.showMessage({
						message: 'Failed to get Packages details.',
						autoHideDuration: 1500,
						variant: 'error',
					}),
				);
				setIsLoading(false);
			});
		return () => {
			isMounted = false;
		};
	}, [id]);

	const handleSubmit = (ev) => {
		ev.preventDefault();
		setErrTxts({});
		if (!form.title) {
			setErrTxts({ ...errTxts, title: 'This field is required' });
			return;
		}
		if (!form.price) {
			setErrTxts({ ...errTxts, price: 'This field is required.' });
			return;
		}
		if (!form.allowed_user_number) {
			setErrTxts({ ...errTxts, no_users: 'This field is required.' });
			return;
		}
		if (typeof form.price !== 'number' && Number.isInteger(form.price)) {
			setErrTxts({ ...errTxts, price: 'Price must be a number' });
			return;
		}
		if (form.features < 1) {
			setErrTxts({ ...errTxts, features: 'This field is required.' });
			return;
		}

		setSubmitLoading(true);
		const payload = {
			package: id,
			title: form?.title,
			allowed_user_number: form.allowed_user_number,
			duration: form.duration,
			image: form.image,
			features: selected,
			price: form.price,
			active: form.active ? 'true' : 'false',
		};

		updatePackage(payload)
			.then((res) => {
				dispatch(
					Actions.showMessage({
						message: 'Package Updated Successfully.',
						autoHideDuration: 1500,
						variant: 'success',
					}),
				);
				history.goBack();
			})
			.catch((err) => {
				dispatch(
					Actions.showMessage({
						message: 'Failed to Updated Package.',
						autoHideDuration: 1500,
						variant: 'error',
					}),
				);
				if (err.response.data.errors) {
					setErrTxts(err.response.data.errors);
				}
			})
			.finally(() => {
				setSubmitLoading(false);
			});
	};

	return (
		<FuseAnimate animation="transition.slideLeftIn" duration={600}>
			<div className="edit-package-div mx-auto">
				<div className="flex  justify-between">
					<div className="flex gap-10">
						<Button onClick={() => history.goBack()}>
							<img
								alt="Go Back"
								className="cursor-pointer"
								src="assets/images/arrow-long.png"
								style={{ width: '25px' }}
							/>
						</Button>
						<h2 className="font-bold">Edit Plan</h2>
					</div>
				</div>

				<div className="bg-white rounded edit-package-form">
					{isLoading ? (
						<div className="flex align-center justify-center">
							<CircularProgress />
						</div>
					) : (
						<>
							<div className="mt-60 flex flex-col md:flex-row" style={{ gap: 30 }}>
								<div className="row-span-2">
									<Avatar src={form?.image} style={{ width: 120, height: 120 }} />
								</div>
								<div className="grid grid-cols-2" style={{ gap: 30, width: '100%' }}>
									<div className="insert-field">
										<TextField
											error={!!errTxts.title?.length}
											helperText={errTxts.title}
											name="title"
											className="text-field"
											label="Plan Name"
											value={form?.title}
											onChange={handleChange}
										/>
									</div>
									<div className="insert-field ">
										<TextField
											error={!!errTxts.price?.length}
											helperText={errTxts.price}
											name="price"
											className="text-field"
											label="Price"
											value={form?.price}
											onChange={handleChange}
										/>
									</div>
									<div className="insert-field ">
										<FormControl className="text-field">
											<InputLabel id="duration">Duration</InputLabel>
											<Select
												error={!!errTxts.duration?.length}
												name="duration"
												value={form?.duration}
												onChange={handleChange}
												labelId="duration"
												id="duration"
												placeholder="duration"
											>
												<MenuItem selected value="week">
													<span id="week">Weekly</span>
												</MenuItem>
												<MenuItem value="month">
													<span id="month">Monthly</span>
												</MenuItem>
											</Select>
										</FormControl>
									</div>
									<div className="insert-field ">
										<TextField
											className="text-field disabled-css"
											name="allowed_user_number"
											label="No of users allowed"
											value={form?.allowed_user_number}
											onChange={handleChange}
											type="number"
										/>
									</div>
									<div className="col-span-2">
										<FormControl error={!!errTxts.features?.length} className="text-field">
											<div className="flex justify-between items-center">
												<div>
													<span>Features:</span>
												</div>
												<div>
													<CustomButton
														aria-describedby={featureListPopoverId}
														variant="contained"
														color="primary"
														width={140}
														onClick={handleOpenFeaturesList}
													>
														Select features
													</CustomButton>

													<Popover
														id={id}
														open={open}
														anchorEl={anchorEl}
														onClose={handleCloseFeaturesList}
														anchorOrigin={{
															vertical: 'bottom',
															horizontal: 'center',
														}}
														transformOrigin={{
															vertical: 'top',
															horizontal: 'center',
														}}
													>
														<List>
															{features.map((value) => {
																const labelId = `checkbox-list-label-${value}`;

																return (
																	<ListItem
																		key={value}
																		role={undefined}
																		dense
																		button
																		onClick={handleFeatureToggle(value)}
																	>
																		<ListItemIcon>
																			<Checkbox
																				edge="start"
																				checked={selected.indexOf(value) !== -1}
																				tabIndex={-1}
																				disableRipple
																				inputProps={{
																					'aria-labelledby': labelId,
																				}}
																			/>
																		</ListItemIcon>
																		<ListItemText
																			id={labelId}
																			primary={`${value}`}
																		/>
																	</ListItem>
																);
															})}
														</List>
													</Popover>
												</div>
											</div>

											<div className="pt-10">
												<ul className="list-disc pl-16">
													{selected.map((feature) => (
														<li className="font-bold" key={feature}>
															{feature}
														</li>
													))}
												</ul>
											</div>
											{!!errTxts.features && <FormHelperText>{errTxts.features}</FormHelperText>}
										</FormControl>
									</div>
									<div className="col-span-2 flex justify-between align-self">
										<p>Enable the selected package from the parent application</p>
										<CustomCheckbox
											row={{ ...form, status: form.active }}
											onClick={() =>
												setForm({ ...form, status: !form.active, active: !form.active })
											}
										/>
									</div>
								</div>
							</div>

							<div className="flex justify-center mt-32" style={{ gap: 20 }}>
								{submitLoading ? (
									<CircularProgress />
								) : (
									<>
										<CustomButton
											variant="secondary"
											width={140}
											id="cancel"
											onClick={() => history.goBack()}
										>
											Cancel
										</CustomButton>
										<CustomButton
											variant="primary"
											width={140}
											id="update-staff"
											onClick={handleSubmit}
										>
											Update
										</CustomButton>
									</>
								)}
							</div>
						</>
					)}
				</div>
			</div>
		</FuseAnimate>
	);
}

export default PackageEdit;
