/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import {
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Paper,
	CircularProgress,
	IconButton,
	TextField,
	InputAdornment,
	Avatar,
	InputLabel,
	Select,
	MenuItem,
	FormControl,
	Popover,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { getUserListing, getSearchableSchoolsList, getPackagesList } from 'app/services/userManagement/userManagement';
import './UserManagement.css';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { useDispatch } from 'react-redux';
import * as Actions from 'app/store/actions';
import InfiniteScroll from 'react-infinite-scroll-component';
import dayjs from 'dayjs';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { generateAgeString } from 'utils/utils';
import ViewAllChildren from './ViewAllChildren';

function UserManagement() {
	const dispatch = useDispatch();
	const [rows, setRows] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [hasMore, setHasMore] = useState(false);
	const [firstLoad, setFirstLoad] = useState(true);
	const [fetchingMore, setFetchingMore] = useState(false);
	const [filters, setFilters] = useState({ subscriber: '', student_name: '', school_id: '', package_id: '' });
	const [schools, setSchools] = useState([]);
	const [packages, setPackages] = useState([]);
	const [page, setPage] = useState(1);
	const [isSchoolloading, setIsSchoolloading] = useState(false);
	const [searchSchoolQuery, setSchoolSearchQuery] = useState();
	const [currentSort, setCurrentSort] = useState(0); // 0 no sort, 1 sort a-z on subscriber name, 2 sort z-a on subscriber name
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	useEffect(() => {
		let isMounted = true;
		getPackagesList()
			.then((res) => {
				if (!isMounted) return;
				setPackages(res.data);
			})
			.catch((err) => {
				if (!isMounted) return;
				dispatch(
					Actions.showMessage({
						message: 'Failed to get packages.',
						autoHideDuration: 1500,
						variant: 'error',
					}),
				);
			});
		return () => {
			isMounted = false;
		};
	}, [dispatch]);

	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(
			() => {
				if (!isMounted) return;
				setIsSchoolloading(true);
				setSchools([]);
				if (!searchSchoolQuery) {
					getSearchableSchoolsList('', 1)
						.then((res) => {
							if (!isMounted) return;
							setSchools(res.data.data);
						})
						.catch((err) => {
							if (!isMounted) return;
							dispatch(
								Actions.showMessage({
									message: 'Failed to get states.',
									autoHideDuration: 1500,
									variant: 'error',
								}),
							);
						})
						.finally(() => {
							if (!isMounted) return;
							setIsSchoolloading(false);
						});
				} else {
					getSearchableSchoolsList(searchSchoolQuery, searchSchoolQuery ? undefined : 1)
						.then((res) => {
							if (!isMounted) return;
							setSchools(res.data.data);
						})
						.catch((err) => {
							if (!isMounted) return;
							dispatch(
								Actions.showMessage({
									message: 'Failed to get states.',
									autoHideDuration: 1500,
									variant: 'error',
								}),
							);
						})
						.finally(() => {
							if (!isMounted) return;
							setIsSchoolloading(false);
						});
				}
			},
			firstLoad ? 0 : 1000,
		);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
	}, [dispatch, searchSchoolQuery, filters]);

	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(
			() => {
				if (!isMounted) return;
				setIsLoading(true);
				getUserListing(filters.subscriber, filters.student_name, filters.school_id, filters.package_id, 1)
					.then((res) => {
						if (!isMounted) return;
						setFirstLoad(false);
						setRows(res.data.data || []);
						setHasMore(res.data.to < res.data.total);
						setPage(res.data.current_page + 1);
					})
					.catch((err) => {
						if (!isMounted) return;
						dispatch(
							Actions.showMessage({
								message: 'Failed to fetch data, please refresh',
								variant: 'error',
							}),
						);
					})
					.finally(() => {
						if (!isMounted) return;
						setIsLoading(false);
					});
			},
			firstLoad ? 0 : 1000,
		);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
		// eslint-disable-next-line
	}, [filters]);

	const handleLoadMore = () => {
		setFetchingMore(true);
		getUserListing(filters.subscriber, filters.student_name, filters.school_id, filters.package_id, page)
			.then((res) => {
				if (res.data.last_page > res.data.current_page) {
					setHasMore(true);
				} else {
					setHasMore(false);
				}
				setPage(res.data.current_page + 1);
				setRows(rows.concat(res.data.data));
				setFetchingMore(false);
			})
			.catch((err) => {
				setFetchingMore(false);
				dispatch(
					Actions.showMessage({
						message: 'Failed to fetch data, please refresh',
						variant: 'error',
					}),
				);
			});
	};

	const handleFilters = (ev) => {
		setFilters({ ...filters, [ev.target.name]: ev.target.value || '' });
	};

	const handleOpenMoreChild = (row) => {
		dispatch(
			Actions.openDialog({
				children: <ViewAllChildren row={row} />,
			}),
		);
	};

	const getUserName = (user) => {
		return user.first_name.toLowerCase() + user.last_name.toLowerCase();
	};

	const sortResolver = (a, b) => {
		if (currentSort === 1) {
			if (getUserName(a.user) < getUserName(b.user)) {
				return -1;
			}
			if (getUserName(a.user) > getUserName(b.user)) {
				return 1;
			}
			return 0;
		}
		if (currentSort === 2) {
			if (getUserName(a.user) > getUserName(b.user)) {
				return -1;
			}
			if (getUserName(a.user) < getUserName(b.user)) {
				return 1;
			}
			return 0;
		}
		return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
	};

	return (
		<FuseAnimate animation="transition.slideLeftIn" duration={600}>
			<div className="mx-auto user-cont">
				<div className="flex items-center flex-nowrap justify-between">
					<h3 className="font-bold mr-28 mt-28 font-20px">User Management</h3>{' '}
					<div className="flex justify-between">
						<div className="flex">
							<TextField
								name="subscriber"
								className="mx-8 w-170px"
								id="subscriber"
								value={filters.subscriber}
								onChange={handleFilters}
								label="Search by Subscriber"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												onClick={() => {
													document.getElementById('subscriber').focus();
												}}
											>
												<img
													alt="search-icon"
													src="assets/images/search-icon.svg"
													height="80%"
													width="80%"
												/>
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
							<TextField
								name="student_name"
								className="mx-8 w-170px"
								id="student_name"
								value={filters.student_name}
								onChange={handleFilters}
								label="Search By Student"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												onClick={() => {
													document.getElementById('student_name').focus();
												}}
											>
												<img
													alt="search-icon"
													src="assets/images/search-icon.svg"
													height="80%"
													width="80%"
												/>
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
							<div className="mx-8">
								<Autocomplete
									id="state-autocomplete"
									className="mx-8"
									options={schools}
									renderOption={(option) => (
										<>
											<div className="flex gap-10">
												<div>{option.name}</div>
											</div>
										</>
									)}
									getOptionLabel={(option) => option.name}
									autoComplete={false}
									clearOnBlur={false}
									disableClearable
									loading={isSchoolloading}
									loadingText="...Loading"
									sx={{ width: '100%' }}
									onChange={(_e, v) => {
										setFilters({ ...filters, school_id: v?.id || '' });
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Filter By School"
											className="w-170px"
											onChange={(e) => {
												setSchoolSearchQuery(e.target.value);
												if (e.target.value === '') {
													setFilters({ ...filters, school_id: '' });
												}
											}}
											autoComplete="off"
										/>
									)}
								/>
							</div>
							<div className="mx-8">
								<FormControl>
									<InputLabel id="roomLabel">Filter By Packages</InputLabel>
									<Select
										name="package_id"
										onChange={handleFilters}
										value={filters.package_id}
										labelId="roomLabel"
										id="package_id"
										label="Filter by Packages"
										className="w-170px"
										endAdornment={
											filters.package_id ? (
												<IconButton
													size="small"
													className="mr-16"
													onClick={() =>
														setFilters({
															...filters,
															package_id: '',
														})
													}
												>
													<Close fontSize="small" />
												</IconButton>
											) : (
												''
											)
										}
									>
										{packages.length ? (
											packages.map((pack) => {
												return (
													<MenuItem key={pack.id} value={pack.id}>
														{pack.title.split('Subscription')}
													</MenuItem>
												);
											})
										) : (
											<MenuItem disabled>Loading...</MenuItem>
										)}
									</Select>
								</FormControl>
							</div>
						</div>
					</div>
				</div>
				<TableContainer id="Scrollable-table" component={Paper} className="user-table-cont">
					<Table stickyHeader className="w-full">
						<TableHead>
							<TableRow>
								<TableCell style={{ width: '20%' }} className="bg-white userTableHeader">
									<div className="flex justify-between items-center">
										Subscriber Name
										<div>
											<IconButton aria-describedby={id} variant="contained" onClick={handleClick}>
												<MoreVertIcon style={{ color: '#1f479c' }} />
											</IconButton>
											<Popover
												id={id}
												open={open}
												anchorEl={anchorEl}
												onClose={handleClose}
												anchorOrigin={{
													vertical: 'bottom',
													horizontal: 'left',
												}}
											>
												<div className="flex flex-col">
													<div
														className="py-4 px-16 cursor-pointer font-small items-start"
														onClick={() => {
															setCurrentSort(1);
															handleClose();
														}}
													>
														Sort A-Z
													</div>
													<div
														className="py-4 px-16 cursor-pointer font-small items-start"
														onClick={() => {
															setCurrentSort(2);
															handleClose();
														}}
													>
														Sort Z-A
													</div>
													<div
														className="py-4 px-16 cursor-pointer font-small items-start"
														onClick={() => {
															setCurrentSort(0);
															handleClose();
														}}
													>
														Clear
													</div>
												</div>
											</Popover>
										</div>
									</div>
								</TableCell>
								<TableCell style={{ width: '20%' }} className="bg-white userTableHeader">
									Student
								</TableCell>
								<TableCell className="bg-white userTableHeader" style={{ width: '15%', padding: 0 }} />
								<TableCell style={{ width: '15%' }} className="bg-white userTableHeader">
									School Name
								</TableCell>
								<TableCell style={{ width: '15%' }} className="bg-white userTableHeader">
									Last Package
								</TableCell>
								<TableCell style={{ width: '15%' }} className="bg-white userTableHeader">
									Current Package
								</TableCell>
								<TableCell style={{ width: '10%' }} className="bg-white userTableHeader">
									Date
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody className="">
							{isLoading ? (
								<TableRow>
									<TableCell align="center" colSpan={8}>
										<CircularProgress />
									</TableCell>
								</TableRow>
							) : !rows?.length && !firstLoad ? (
								<TableRow>
									<TableCell align="center" colSpan={8}>
										No User
									</TableCell>
								</TableRow>
							) : (
								rows
									.sort((a, b) => {
										return sortResolver(a, b);
									})
									?.map((row, index) => (
										<TableRow key={row.id}>
											<TableCell className="font-bold" component="th" scope="row">
												<div id={`student-${row.id}`} className="flex">
													<Avatar
														className="mr-6"
														alt="student-face"
														src={row?.user?.thumb}
													/>
													<div className="flex items-center">
														<div className="student-name truncate break-all">
															{row?.user?.first_name} {row?.user?.last_name}
														</div>
													</div>
												</div>
											</TableCell>
											<TableCell align="left">
												<div className="flex items-center justify-between">
													<div className="flex items-center">
														{row.family_child_all.length > 2
															? row.family_child_all.slice(0, 2).map((child, i) => {
																	return (
																		<div
																			className="flex items-center margin-right-20 parent-column"
																			key={i}
																		>
																			<Avatar
																				className="mr-6"
																				alt="student-face"
																				src={child.thumb}
																			/>
																			<div className="flex flex-col items-start">
																				<div className="student-name truncate break-all w-90px">
																					{child.first_name} {child.last_name}
																				</div>
																				<div className="font-normal truncate student-age-font w-90px">
																					{generateAgeString(
																						child?.date_of_birth,
																					)}
																				</div>
																			</div>
																		</div>
																	);
																})
															: row.family_child_all.map((child, i) => {
																	return (
																		<div
																			className="flex items-center parent-column margin-right-20"
																			key={i}
																		>
																			<Avatar
																				className="mr-6"
																				alt="student-face"
																				src={child.thumb}
																			/>
																			<div className="flex flex-col items-start">
																				<div className="student-name truncate break-all  w-90px">
																					{child.first_name} {child.last_name}
																				</div>
																				<div className="font-normal truncate student-age-font w-90px">
																					{generateAgeString(
																						child?.date_of_birth,
																					)}
																				</div>
																			</div>
																		</div>
																	);
																})}
													</div>
													<div />
												</div>
											</TableCell>
											<TableCell className="p-0">
												{row.family_child_all.length > 2 ? (
													<h5
														className="child-num cursor-pointer"
														onClick={() => handleOpenMoreChild(row)}
													>
														&nbsp; +
														{row.family_child_all.length > 2 &&
															`${row.family_child_all.length - 2} Others`}{' '}
													</h5>
												) : (
													<></>
												)}
											</TableCell>
											<TableCell align="left">
												<div className="package-name">{row?.school?.name}</div>
											</TableCell>
											<TableCell align="left">
												<div className="package-name">
													{row?.previous_subscription
														? row?.previous_subscription.title.split('Subscription')
														: '-'}
												</div>
											</TableCell>
											<TableCell align="left">
												<div className="package-name">
													{row?.package?.title.split('Subscription')}
												</div>
											</TableCell>
											<TableCell>
												<div className="flex flex-col">
													<div className="font-semibold text-black font-12px">
														{dayjs(row?.updated_at).format('MM/DD/YYYY')}
													</div>
													<div className="subscribe-date">
														{dayjs(row?.updated_at).format('h:mm A')}
													</div>
												</div>
											</TableCell>
										</TableRow>
									))
							)}
							{fetchingMore ? (
								<TableRow>
									<TableCell align="center" colSpan={8}>
										<CircularProgress />
									</TableCell>
								</TableRow>
							) : (
								<></>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<InfiniteScroll
					dataLength={rows.length}
					next={handleLoadMore}
					hasMore={hasMore}
					scrollableTarget="Scrollable-table"
				/>
			</div>
		</FuseAnimate>
	);
}
export default UserManagement;
