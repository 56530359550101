import React, { useState, useEffect } from 'react';
import {
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Paper,
	CircularProgress,
	TextField,
	Avatar,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { getCompanyList, getSchoolRoyalties } from 'app/services/paymentManagement/paymentManagement';
import './SchoolRoyalties.css';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { useDispatch } from 'react-redux';
import * as Actions from 'app/store/actions';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';

function SchoolRoyalties({ setActiveId }) {
	const dispatch = useDispatch();
	const [rows, setRows] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [hasMore, setHasMore] = useState(false);
	const [firstLoad, setFirstLoad] = useState(true);
	const [fetchingMore, setFetchingMore] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [filters, setFilters] = useState({ company_id: '' });
	const [page, setPage] = useState(1);
	const [companies, setCompanies] = useState([]);
	const [companyPage, setCompanyPage] = useState(1);
	const [isCompanyloading, setIsCompanyloading] = useState(false);
	const [searchCompanyQuery, setCompanySearchQuery] = useState();
	// const { id } = row;

	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(
			() => {
				if (!isMounted) return;
				setIsCompanyloading(true);
				setCompanies([]);
				if (!searchCompanyQuery) {
					getCompanyList('', '', '', 1)
						.then((res) => {
							if (!isMounted) return;
							setCompanies(res.data.data);
						})
						.catch((err) => {
							if (!isMounted) return;
							dispatch(
								Actions.showMessage({
									message: 'Failed to get companies.',
									autoHideDuration: 1500,
									variant: 'error',
								}),
							);
						})
						.finally(() => {
							if (!isMounted) return;
							setIsCompanyloading(false);
						});
				} else {
					getCompanyList(searchCompanyQuery, '', '', 1)
						.then((res) => {
							if (!isMounted) return;
							setCompanies(res.data.data);
						})
						.catch((err) => {
							if (!isMounted) return;
							dispatch(
								Actions.showMessage({
									message: 'Failed to get companies.',
									autoHideDuration: 1500,
									variant: 'error',
								}),
							);
						})
						.finally(() => {
							if (!isMounted) return;
							setIsCompanyloading(false);
						});
				}
			},
			firstLoad ? 0 : 1000,
		);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
	}, [dispatch, searchCompanyQuery, filters]);

	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(
			() => {
				if (!isMounted) return;
				setIsLoading(true);
				getSchoolRoyalties(filters.company_id, 1)
					.then((res) => {
						if (!isMounted) return;
						setFirstLoad(false);
						setRows(res.data.data || []);
						setHasMore(res.data.to < res.data.total);
						setPage(res.data.current_page + 1);
					})
					.catch((err) => {
						if (!isMounted) return;
						dispatch(
							Actions.showMessage({
								message: 'Failed to fetch data, please refresh',
								variant: 'error',
							}),
						);
					})
					.finally(() => {
						if (!isMounted) return;
						setIsLoading(false);
					});
			},
			firstLoad ? 0 : 1000,
		);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
		// eslint-disable-next-line
	}, [refresh, filters]);

	const handleLoadMore = () => {
		setFetchingMore(true);
		getSchoolRoyalties(filters.company_id, page)
			.then((res) => {
				if (res.data.last_page > res.data.current_page) {
					setHasMore(true);
				} else {
					setHasMore(false);
				}
				setPage(res.data.current_page + 1);
				setRows(rows.concat(res.data.data));
				setFetchingMore(false);
			})
			.catch((err) => {
				setFetchingMore(false);
				dispatch(
					Actions.showMessage({
						message: 'Failed to fetch data, please refresh',
						variant: 'error',
					}),
				);
			});
	};

	const handleFilters = (ev) => {
		if (ev.target.name === 'company_id') {
			setFilters({ ...filters, company_id: ev.target.value });
		}
	};

	const handleOpenSchoolRoyaltyInfo = (row) => {
		setActiveId(row.id);
		// setCompany_id(row.company_id)
	};
	return (
		<FuseAnimate animation="transition.slideLeftIn" duration={600}>
			<div className="mx-auto payment-cont">
				<div className="flex items-center flex-nowrap justify-between">
					<h3 className="font-extrabold mr-28 mt-28" style={{ fontSize: '20px', fontWeight: '700' }}>
						Royalties
					</h3>{' '}
					<div className="flex justify-between">
						<div className="flex">
							<div className="mx-8">
								{/* <FormControl>
									<InputLabel id="roomLabel">Filter by Company</InputLabel>
									<Select
										name="company_id"
										onChange={handleFilters}
										value={filters.company_id}
										labelId="roomLabel"
										id="company_id"
										label="Filter by School"
										style={{ width: 170 }}
										endAdornment={
											filters.company_id ? (
												<IconButton size="small" className="mr-16">
													<Close
														onClick={() =>
															setFilters({
																...filters,
																company_id: ''
															})
														}
														fontSize="small"
													/>
												</IconButton>
											) : (
												''
											)
										}
									>
										{companies.length ? (
											companies.map(company => {
												return (
													<MenuItem key={company.id} value={company.id}>
														{company.name}
													</MenuItem>
												);
											})
										) : (
											<MenuItem disabled>Loading...</MenuItem>
										)}
									</Select>
								</FormControl> */}
								<Autocomplete
									id="state-autocomplete"
									className="mx-8"
									options={companies}
									renderOption={(option) => (
										<>
											<div className="flex" style={{ gap: 10 }}>
												<div>{option.name}</div>
											</div>
										</>
									)}
									getOptionLabel={(option) => option.name}
									autoComplete={false}
									clearOnBlur={false}
									disableClearable
									loading={isCompanyloading}
									loadingText="...Loading"
									sx={{ width: '100%' }}
									onChange={(_e, v) => {
										setFilters({ ...filters, company_id: v?.id || '' });
									}}
									onInputChange={(e, value) => {
										setCompanySearchQuery(value);
										if (value === '') {
											setFilters({ ...filters, company_id: '' });
										}
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Filter by Company"
											style={{ width: '170px' }}
											autoComplete="off"
										/>
									)}
								/>
							</div>
						</div>
					</div>
				</div>
				<TableContainer id="Scrollable-table" component={Paper} className="payment-table-cont">
					<Table stickyHeader className="payment-table" style={{ width: '100%' }}>
						<TableHead>
							<TableRow>
								<TableCell style={{ width: '20%' }} className="bg-white paymentTableHeader">
									Company Name
								</TableCell>
								<TableCell style={{ width: '20%' }} className="bg-white companyTableHeader">
									Total No of School's
								</TableCell>
								<TableCell style={{ width: '20%' }} className="bg-white companyTableHeader">
									Active Subscriber
								</TableCell>
								<TableCell style={{ width: '15%' }} className="bg-white companyTableHeader">
									Pending Amount
								</TableCell>
								<TableCell style={{ width: '15%' }} className="bg-white companyTableHeader">
									Total Amount
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody className="">
							{isLoading ? (
								<TableRow>
									<TableCell align="center" colSpan={8}>
										<CircularProgress />
									</TableCell>
								</TableRow>
							) : !rows.length && !firstLoad ? (
								<TableRow>
									<TableCell align="center" colSpan={8}>
										No records found
									</TableCell>
								</TableRow>
							) : (
								rows?.map((row, index) => (
									<TableRow
										key={row.id}
										onClick={() => handleOpenSchoolRoyaltyInfo(row)}
										className="cursor-pointer"
									>
										<TableCell
											style={{ fontWeight: '700' }}
											component="th"
											scope="row"
											className="font-bold"
											// onClick={() => setCurrentScreen(0)}
										>
											<div id={`company-${row.id}`} className="flex">
												<Avatar className="mr-6" alt="student-face" src={row?.logo} />
												<div className="flex items-center">
													<div className="company-name">{row?.name}</div>
												</div>
											</div>
										</TableCell>
										<TableCell align="left">
											<div style={{ fontSize: 12 }} className="values-font">
												{row?.branches_count}
											</div>
										</TableCell>
										<TableCell align="left">
											<div style={{ fontSize: 12 }} className="values-font">
												{row?.subscriptions.length}
											</div>
										</TableCell>
										<TableCell align="left">
											<div style={{ fontSize: 12 }} className="values-font">
												$&nbsp;{row?.pending_amount}
											</div>
										</TableCell>
										<TableCell align="left">
											<div style={{ fontSize: 12 }} className="values-font">
												$&nbsp;{row?.company_balance}
											</div>
										</TableCell>
										{/* <TableCell>
											<div className="flex flex-col">
												<div style={{ fontSize: 12 }}>
													{moment
														.utc(row?.subscriptions.created_at)
														.local()
														.format('L')}
												</div>
												<div className="subscribe-date">
													{moment
														.utc(row?.subscriptions.created_at)
														.local()
														.format('LT')}
												</div>
											</div>
										</TableCell> */}
									</TableRow>
								))
							)}
							{fetchingMore ? (
								<TableRow>
									<TableCell align="center" colSpan={8}>
										<CircularProgress />
									</TableCell>
								</TableRow>
							) : (
								<></>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<InfiniteScroll
					dataLength={rows.length}
					next={handleLoadMore}
					hasMore={hasMore}
					scrollableTarget="Scrollable-table"
				/>
			</div>
		</FuseAnimate>
	);
}
export default SchoolRoyalties;
