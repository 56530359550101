/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import './Company.css';
import FuseAnimate from '@fuse/core/FuseAnimate';
import history from '@history';
import {
	FormControl,
	FormHelperText,
	CircularProgress,
	Select,
	InputLabel,
	MenuItem,
	TextField,
	Button,
	Avatar,
} from '@material-ui/core';
import {
	updateCompanySchool,
	getSearchableStateList,
	getCountryList,
	getSearchableCityList,
	getschooldetail,
} from 'app/services/Company/companyService';
import { useDispatch } from 'react-redux';
import * as Actions from 'app/store/actions/';
import CustomButton from 'app/customComponents/CustomButton/CustomButton';
import { useParams } from 'react-router-dom';
import { uploadFile } from 'app/services/imageUpload/imageUpload';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getImageUrl, isSameIPVersion } from 'utils/utils';
import { makeStyles } from '@material-ui/core/styles';
import CustomCheckbox from 'app/customComponents/CustomCheckbox/CustomCheckbox';
import isIP from 'validator/lib/isIP';
import ConfirmationPopup from './ConfirmationPopup';
import tzlookup from 'tz-lookup';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		display: 'flex',
		position: 'fixed',
		width: '100%',
		height: '100%',
		flex: '1 1 auto',
	},
	select: {
		'&:before': {
			borderBottom: 'none',
		},
		'&:after': {
			borderBottom: 'none',
		},
		'&:not(.Mui-disabled):hover::before': {
			borderBottom: 'none',
		},
		'& .MuiSelect-select:focus': {
			backgroundColor: 'inherit',
		},
		'& .MuiSvgIcon-root': {
			color: 'inherit',
		},
		color: 'inherit',
		'&:hover': {
			color: 'inherit',
		},
	},
}));

function EditSchools() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const inputRef = useRef(null);
	const { id } = useParams();
	const [modifiedRow, setModifiedRow] = useState({
		status: 1,
	});
	const [isUpdating, setIsUpdating] = useState(false);
	const [errTxts, setErrTxts] = useState({});
	const [countries, setCountries] = useState([]);
	const [states, setStates] = useState([]);
	const [city, setCity] = useState([]);
	const [latLong, setLatLong] = useState({ lat: '', long: '' });
	const [cityTimezone, setCityTimeZone] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [preview, setPreview] = useState(null);
	const [selectedFile, setSelectedFile] = useState(null);
	const [isStateloading, setIsStateloading] = useState(false);
	const [searchStateQuery, setStateSearchQuery] = useState('');
	const [isCityLoading, setIsCityLoading] = useState(false);
	const [searchCityQuery, setSearchCityQuery] = useState('');
	const [defaultCity, setDefaultCity] = useState();
	const [defaultState, setDefaultState] = useState({});
	const isIPDataChanged = useRef(false);

	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(() => {
			if (!isMounted) return;
			setIsLoading(true);
			getschooldetail(id)
				.then((res) => {
					if (!isMounted) return;
					setModifiedRow({
						...modifiedRow,
						admin_firstname: res.data.super_admin[0].first_name,
						admin_lastname: res.data.super_admin[0].last_name,
						email: res.data.super_admin[0].email,
						admin_phone: res.data.super_admin[0].phone,
						designation: res.data.super_admin[0].designation,
						school_name: res.data.name,
						school_phone: res.data.phone,
						country_code: res.data.country_code,
						city: res.data.city,
						state_id: res.data.state_id,
						address: res.data.address,
						street: res.data.street,
						zip_code: res.data.zip_code,
						timezone: res.data.timezone,
						status: res.data.status,
						ip_restriction: res.data.ip_restriction,
						ip_ranges:
							res.data.ip_ranges?.map((ip) => {
								return ip.start === ip.end ? [ip.start] : [ip.start, ip.end];
							}) || [],
					});
					setDefaultState(res.data.state);
					setDefaultCity({ name: res.data.city });
					setPreview(res?.data?.logo);
				})
				.catch((err) => {
					if (!isMounted) return;
					console.log(err);
				})
				.finally(() => {
					if (!isMounted) return;
					setIsLoading(false);
				});
		}, 1000);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
	}, [id]);

	useEffect(() => {
		if (modifiedRow.state_id && states.length > 0) {
			setDefaultState({ id: defaultState.id, name: defaultState.name });
			setDefaultCity({ name: modifiedRow.city });
		}
	}, [isLoading, states]);

	const handleChange = (ev) => {
		const { name, value } = ev.target;
		if (name === 'country_code') {
			setErrTxts({ ...errTxts, country_code: '' });
			setModifiedRow({ ...modifiedRow, country_code: value, state_id: '', city: '' });
			return;
		}
		if (name === 'state_id') {
			setErrTxts({ ...errTxts, state_id: '' });
			setModifiedRow({ ...modifiedRow, state_id: value, city: '' });
			return;
		}
		setModifiedRow({ ...modifiedRow, [name]: value });
		setErrTxts({ ...errTxts, [name]: '' });
	};

	useEffect(() => {
		if (!selectedFile) {
			setPreview(null);
			return;
		}

		const objectUrl = URL.createObjectURL(selectedFile);
		setPreview(objectUrl);

		return () => URL.revokeObjectURL(objectUrl);
	}, [selectedFile]);

	const onSelectFile = (e) => {
		if (!e.target.files || e.target.files.length === 0) {
			setSelectedFile(null);
			return;
		}

		setSelectedFile(e.target.files[0]);
	};

	const handleUpdate = () => {
		const data = JSON.parse(JSON.stringify(modifiedRow));

		if (!data.ip_restriction) {
			data.ip_ranges = [];
		}

		data.school_phone = modifiedRow.school_phone;
		if (data.school_phone) {
			data.school_phone = parseInt(
				data.school_phone.split(' ').join('').split('-').join('').split('(').join('').split(')').join(''),
				10,
			);
		}
		data.admin_phone = modifiedRow.admin_phone;
		if (data.admin_phone) {
			data.admin_phone = parseInt(
				data.admin_phone.split(' ').join('').split('-').join('').split('(').join('').split(')').join(''),
				10,
			);
		}
		if (cityTimezone) {
			data.timezone = cityTimezone;
		}
		if (selectedFile) {
			const filename = getImageUrl(selectedFile);
			setIsUpdating(true);
			uploadFile(selectedFile, filename).then((response) => {
				data.school_profile_image = `${import.meta.env.VITE_S3_BASE_URL}${response}`;
				updateCompanySchool(id, data)
					.then((res) => {
						setIsUpdating(false);
						dispatch(
							Actions.showMessage({
								message: res.data.message,
								variant: 'success',
							}),
						);

						history.goBack();
					})
					.catch((err) => {
						setIsUpdating(false);
						dispatch(
							Actions.showMessage({
								message: 'Failed to update School Data',
								variant: 'error',
							}),
						);
						if (err?.response?.data?.errors) {
							setErrTxts(err.response.data.errors);
						}
					})
					.finally(() => {
						setIsUpdating(false);
					});
				console.log(data, 'data with image');
			});
		} else {
			setIsUpdating(true);
			updateCompanySchool(id, data)
				.then((res) => {
					setIsUpdating(false);
					dispatch(
						Actions.showMessage({
							message: res.data.message,
							variant: 'success',
						}),
					);

					history.goBack();
				})
				.catch((err) => {
					setIsUpdating(false);
					dispatch(
						Actions.showMessage({
							message: 'Failed to update School Data',
							variant: 'error',
						}),
					);
					if (err?.response?.data?.errors) {
						setErrTxts(err.response.data.errors);
					}
				})
				.finally(() => {
					setIsUpdating(false);
				});
			console.log(data, 'data without image');
		}
	};

	useEffect(() => {
		if (latLong.lat !== '' && latLong.long !== '') {
			console.log(tzlookup(latLong.lat, latLong.long));
			setCityTimeZone(tzlookup(latLong.lat, latLong.long));
		}
	}, [latLong]);

	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(() => {
			if (!isMounted) return;
			setIsStateloading(true);
			setStates([]);
			getSearchableStateList(searchStateQuery, searchStateQuery ? undefined : 1)
				.then((res) => {
					if (!isMounted) return;
					setStates(res.data.data);
				})
				.catch((err) => {
					if (!isMounted) return;
					dispatch(
						Actions.showMessage({
							message: 'Failed to get states.',
							autoHideDuration: 1500,
							variant: 'error',
						}),
					);
				})
				.finally(() => {
					if (!isMounted) return;
					setIsStateloading(false);
				});
		}, 1000);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
	}, [dispatch, modifiedRow.country_code, searchStateQuery]);

	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(() => {
			if (!isMounted) return;
			setIsCityLoading(true);
			setCity([]);
			if (!searchCityQuery && !modifiedRow.state_id) {
				setCity([]);
			} else {
				getSearchableCityList(modifiedRow.state_id, searchCityQuery, 1)
					.then((res) => {
						if (!isMounted) return;
						setCity(res.data.data);
					})
					.catch((err) => {
						if (!isMounted) return;
						dispatch(
							Actions.showMessage({
								message: 'Failed to get cities.',
								autoHideDuration: 1500,
								variant: 'error',
							}),
						);
					})
					.finally(() => {
						if (!isMounted) return;
						setIsCityLoading(false);
					});
			}
		}, 1000);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
	}, [modifiedRow.state_id, searchCityQuery]);

	useEffect(() => {
		let isMounted = true;
		setIsLoading(true);
		getCountryList()
			.then((res) => {
				if (!isMounted) return;
				setCountries(res.data);
			})
			.catch((err) => {
				if (!isMounted) return;
				dispatch(
					Actions.showMessage({
						message: 'Failed to get countries.',
						autoHideDuration: 1500,
						variant: 'error',
					}),
				);
			});
		return () => {
			isMounted = false;
		};
	}, []);

	const handleIPUpdate = (flag) => {
		if (flag === 'CANCEL') {
			dispatch(
				Actions.openDialog({
					children: (
						<ConfirmationPopup
							callback={history.goBack}
							message="Are you sure you want to cancel? Your changes will be discarded."
						/>
					),
				}),
			);
		} else if (flag === 'SUBMIT') {
			setErrTxts({});
			if (!modifiedRow.admin_firstname) {
				setErrTxts({ ...errTxts, admin_firstname: 'This field is required' });
				return;
			}
			if (modifiedRow.admin_firstname && /[^a-z A-Z]/.test(modifiedRow.admin_firstname)) {
				setErrTxts({ ...errTxts, admin_firstname: 'Please enter a valid value.' });
				return;
			}
			if (!modifiedRow.admin_lastname) {
				setErrTxts({ ...errTxts, admin_lastname: 'This field is required' });
				return;
			}
			if (modifiedRow.admin_lastname && /[^a-z A-Z]/.test(modifiedRow.admin_lastname)) {
				setErrTxts({ ...errTxts, admin_lastname: 'Please enter a valid value.' });
				return;
			}
			if (!modifiedRow.email) {
				setErrTxts({ ...errTxts, email: 'This field is required' });
				return;
			}
			if (!/^\S+@\S+\.\S+$/.test(modifiedRow.email)) {
				setErrTxts({ ...errTxts, email: 'Please enter valid email' });
				return;
			}
			if (!modifiedRow.admin_phone) {
				setErrTxts({ ...errTxts, admin_phone: 'This field is required' });
				return;
			}
			if (modifiedRow.admin_phone) {
				if (
					!Number.isFinite(
						Number(
							modifiedRow.admin_phone
								.split(' ')
								.join('')
								.split('-')
								.join('')
								.split('(')
								.join('')
								.split(')')
								.join(''),
						),
					)
				) {
					setErrTxts({ ...errTxts, admin_phone: 'Please enter valid phone number' });
					return;
				}
			}
			if (!modifiedRow.designation) {
				setErrTxts({ ...errTxts, designation: 'This field is required.' });
				return;
			}
			if (modifiedRow.designation && /[^a-z A-Z]/.test(modifiedRow.designation)) {
				setErrTxts({ ...errTxts, designation: 'Please enter a valid value.' });
				return;
			}
			if (!modifiedRow.school_name) {
				setErrTxts({ ...errTxts, school_name: 'This field is required.' });
				return;
			}
			if (modifiedRow.school_name && /[^a-z A-Z]/.test(modifiedRow.school_name)) {
				setErrTxts({ ...errTxts, school_name: 'Please enter a valid value.' });
				return;
			}
			if (!modifiedRow.school_phone) {
				setErrTxts({ ...errTxts, school_phone: 'This field is required.' });
				return;
			}
			if (modifiedRow.school_phone) {
				if (
					!Number.isFinite(
						Number(
							modifiedRow.school_phone
								.split(' ')
								.join('')
								.split('-')
								.join('')
								.split('(')
								.join('')
								.split(')')
								.join(''),
						),
					)
				) {
					setErrTxts({ ...errTxts, school_phone: 'Please enter valid phone number' });
					return;
				}
			}
			if (!modifiedRow.address) {
				setErrTxts({ ...errTxts, address: 'This field is required.' });
				return;
			}

			if (!modifiedRow.country_code) {
				setErrTxts({ ...errTxts, country_code: 'This field is required.' });
				return;
			}
			if (!modifiedRow.state_id) {
				setErrTxts({ ...errTxts, state_id: 'This field is required.' });
				return;
			}
			if (!modifiedRow.city) {
				setErrTxts({ ...errTxts, city: 'This field is required.' });
				return;
			}
			if (!modifiedRow.zip_code) {
				setErrTxts({ ...errTxts, zip_code: 'This field is required.' });
				return;
			}
			if (typeof modifiedRow.zip_code !== 'number' && Number.isInteger(modifiedRow.zip_code)) {
				setErrTxts({ ...errTxts, zip_code: 'Zipcode must be a number' });
				return;
			}

			if (modifiedRow.ip_restriction && modifiedRow.ip_ranges?.length <= 0) {
				setErrTxts({ ...errTxts, ip_restriction: 'Please enter atleast one IP' });
				return;
			}

			if (modifiedRow.ip_restriction && modifiedRow.ip_ranges?.length > 0) {
				for (let i = 0; i < modifiedRow.ip_ranges.length; i++) {
					let iserr = false;
					let err = {};
					if (!isIP(modifiedRow.ip_ranges[i][0])) {
						err[`ip_ranges.${i}.0`] = 'Please enter valid IP';
						iserr = true;
					} else if (modifiedRow.ip_ranges[i].length > 1 && !isIP(modifiedRow.ip_ranges[i][1])) {
						err[`ip_ranges.${i}.1`] = 'Please enter valid IP';
						iserr = true;
					} else if (modifiedRow.ip_ranges[i].length > 1 && !isSameIPVersion(...modifiedRow.ip_ranges[i])) {
						err[`ip_ranges.${i}.0`] = 'IPs must be of same version';
						err[`ip_ranges.${i}.1`] = 'IPs must be of same version';
						iserr = true;
					}
					if (iserr) {
						setErrTxts({ ...errTxts, ...err });
						return;
					}
				}
			}
			dispatch(
				Actions.openDialog({
					children: (
						<ConfirmationPopup
							callback={handleUpdate}
							message="Are you sure you want to save the changes?"
						/>
					),
				}),
			);
		}
	};

	return (
		<FuseAnimate animation="transition.slideLeftIn" duration={600}>
			<FuseScrollbars className={classes.content} scrollToTopOnRouteChange>
				<div className="add-school-div mx-auto">
					<div className="main-box-form justify-between">
						<div className=" flex justify-between ">
							<div className="flex gap-10">
								<Button onClick={() => history.goBack()}>
									<img
										alt="Go Back"
										className="cursor-pointer"
										src="assets/images/arrow-long.png"
										style={{ width: '25px' }}
									/>
								</Button>
								<h2 className="font-bold" style={{ fontSize: '20px' }}>
									{' '}
									Edit School Detail
								</h2>
							</div>
						</div>

						<div className="edit-school-form">
							{isLoading ? (
								<CircularProgress className="m-auto justtify-center items-center flex" />
							) : (
								<form>
									<h2 className="font-bold" style={{ fontSize: '18px' }}>
										School Admin Info
									</h2>
									<fieldset>
										<div className="flex col-gap-52 mt-12">
											<div
												onClick={() => inputRef.current.click()}
												className="row-span-2 camera-holder"
												style={{ justifySelf: 'center' }}
											>
												<Avatar src={preview} style={{ width: 120, height: 120 }} />
												<div className="company-pp-overlay">
													<i className="fa fa-2x fa-camera" />
												</div>
												<input
													onChange={onSelectFile}
													type="file"
													name="file"
													id="image"
													className="hidden"
													ref={inputRef}
												/>
											</div>
											<div>
												<div className="grid grid-cols-3 gap-64 lg:col-gap-136">
													<TextField
														error={!!errTxts.admin_firstname}
														helperText={errTxts.admin_firstname}
														name="admin_firstname"
														value={modifiedRow.admin_firstname}
														onChange={handleChange}
														label="First Name "
														id="admin_firstname"
													/>
													<TextField
														error={!!errTxts.admin_lastname}
														helperText={errTxts.admin_lastname}
														name="admin_lastname"
														value={modifiedRow.admin_lastname}
														onChange={handleChange}
														id="admin_lastname"
														label="Last Name"
													/>
													<TextField
														error={!!errTxts.email}
														helperText={errTxts.email}
														name="email"
														value={modifiedRow.email}
														onChange={handleChange}
														id="email"
														label="Email Address"
														disabled
													/>
													<TextField
														error={!!errTxts.admin_phone}
														helperText={errTxts.admin_phone}
														name="admin_phone"
														value={modifiedRow.admin_phone}
														onChange={handleChange}
														id="admin_phone"
														label="Contact Number"
													/>
													<TextField
														error={!!errTxts.designation}
														helperText={errTxts.designation}
														name="designation"
														value={modifiedRow.designation}
														onChange={handleChange}
														id="designation"
														label="designation"
													/>
												</div>
												<h2 className="font-bold my-32" style={{ fontSize: '18px' }}>
													School Info
												</h2>
												<div className="grid grid-cols-3 gap-64 lg:col-gap-136">
													<TextField
														error={!!errTxts?.school_name}
														helperText={errTxts?.school_name}
														name="school_name"
														value={modifiedRow?.school_name}
														onChange={handleChange}
														id="school_name"
														label="Name of School"
													/>
													<TextField
														error={!!errTxts?.school_phone}
														helperText={errTxts?.school_phone}
														name="school_phone"
														value={modifiedRow?.school_phone}
														onChange={handleChange}
														id="school_phone"
														label="Contact Number"
													/>
													<TextField
														error={!!errTxts?.address}
														helperText={errTxts?.address}
														name="address"
														value={modifiedRow?.address}
														onChange={handleChange}
														id="address"
														label="Address 1"
													/>
													<TextField
														// error={!!errTxts?.street}
														helperText={errTxts?.street}
														name="street"
														value={modifiedRow?.street}
														onChange={handleChange}
														id="street"
														label="Address 2"
													/>
													<FormControl error={!!errTxts?.country_code}>
														<InputLabel id="country_code">Country</InputLabel>
														<Select
															name="country_code"
															value={modifiedRow.country_code}
															onChange={handleChange}
															labelId="country_code"
															id="country_code"
														>
															{countries?.length ? (
																countries.map((country) => {
																	return (
																		<MenuItem
																			key={country.id + 60}
																			value={country.code}
																		>
																			{country.name}
																		</MenuItem>
																	);
																})
															) : (
																<MenuItem>Loading...</MenuItem>
															)}
														</Select>
														{errTxts?.country_code && (
															<FormHelperText>{errTxts?.country_code}</FormHelperText>
														)}
													</FormControl>
													<Autocomplete
														id="state-autocomplete"
														name="state_id"
														options={states}
														renderOption={(option) => (
															<>
																<div className="flex" style={{ gap: 10 }}>
																	<div>{option.name}</div>
																</div>
															</>
														)}
														getOptionLabel={(option) => option.name}
														autoComplete={false}
														clearOnBlur={false}
														disableClearable
														loading={isStateloading}
														loadingText="...Loading"
														sx={{ width: '100%' }}
														onChange={(_e, v) => {
															setDefaultState(v);
															setModifiedRow({
																...modifiedRow,
																state_id: v?.id || '',
															});
														}}
														onInputChange={(e, value) => {
															setStateSearchQuery(value);
															if (value === '') {
																setModifiedRow({
																	...modifiedRow,
																	state_id: '',
																	city: '',
																});
																setDefaultCity({ ...defaultState, name: '' });
															}
														}}
														getOptionSelected={(option, value) => {
															if (option.id === value?.id) {
																return true;
															}
															if (
																defaultState?.id &&
																states.length &&
																!states.find((state) => state.id === defaultState.id)
															) {
																// fix for default state not found in states list while still loading
																return true;
															}
															return false;
														}}
														value={defaultState}
														renderInput={(params) => (
															<TextField
																{...params}
																label="State"
																error={!!errTxts.state_id?.length}
																helperText={errTxts.state_id}
																autoComplete="off"
																// defaultValue={defaultState}
															/>
														)}
													/>
													<Autocomplete
														id="city-autocomplete"
														name="city"
														options={city}
														renderOption={(option) => (
															<>
																<div className="flex" style={{ gap: 10 }}>
																	<div>{option.name}</div>
																</div>
															</>
														)}
														getOptionLabel={(option) => option.name}
														autoComplete={false}
														clearOnBlur={false}
														disableClearable
														loading={isCityLoading}
														loadingText="...Loading"
														sx={{ width: '100%' }}
														onChange={(_e, v) => {
															setDefaultCity(v);
															const value = JSON.parse(v?.meta);
															setLatLong({
																...latLong,
																lat: value.latitude,
																long: value.longitude,
															});
															setModifiedRow({ ...modifiedRow, city: v?.name });
														}}
														getOptionSelected={(option, value) => {
															if (option.id === value?.id) {
																return true;
															}
															if (
																defaultCity?.name &&
																city.length &&
																!city.find((c) => c.name === defaultCity.name)
															) {
																// fix for default city not found in cities list while still loading
																return true;
															}
															return false;
														}}
														value={defaultCity}
														renderInput={(params) => (
															<TextField
																{...params}
																label="City"
																error={!!errTxts.city?.length}
																helperText={errTxts.city}
																autoComplete="off"
																onChange={(e) => setSearchCityQuery(e.target.value)}
																// defaultValue={defaultCity}
															/>
														)}
													/>
													<TextField
														error={!!errTxts.zip_code}
														helperText={errTxts.zip_code}
														name="zip_code"
														value={modifiedRow.zip_code}
														onChange={handleChange}
														id="zip_code"
														label="Zipcode"
													/>
													<FormControl>
														<InputLabel id="state-label">Status</InputLabel>
														<Select
															name="status"
															value={modifiedRow.status}
															onChange={handleChange}
															labelId="status-label"
															id="status"
															placeholder="Status"
															disabled
														>
															<MenuItem selected value={1}>
																Active
															</MenuItem>
															<MenuItem value={0}>Inactive</MenuItem>
														</Select>
													</FormControl>
												</div>
												<div className="flex justify-start gap-64 lg:col-gap-136 my-32">
													<FormControl
														className="flex-grow-1 w-full"
														error={errTxts.ip_restriction}
													>
														<div className="flex items-center justify-between">
															<div>
																<div>
																	IP Filtering
																	<CustomCheckbox
																		id={`toogle=school-ip-filtering`}
																		onClick={() => {
																			setModifiedRow({
																				...modifiedRow,
																				ip_restriction:
																					modifiedRow.ip_restriction ? 0 : 1,
																			});
																			isIPDataChanged.current = true;
																		}}
																		row={{
																			...modifiedRow,
																			status: modifiedRow.ip_restriction,
																		}}
																	/>
																</div>
																{errTxts.ip_restriction && (
																	<FormHelperText>
																		{errTxts.ip_restriction}
																	</FormHelperText>
																)}
															</div>
															{!!modifiedRow.ip_restriction && (
																<CustomButton
																	variant="primary"
																	height="40"
																	width="110px"
																	fontSize="14px"
																	padding="2px"
																>
																	<Select
																		className={classes.select}
																		inputProps={{
																			classes: {
																				root: classes.root,
																				icon: classes.icon,
																			},
																		}}
																		value="Add IP"
																		name="ipadd"
																		id="ip-add-btn"
																		// onChange={e => setActiveTab(e.target.value)}
																	>
																		<MenuItem
																			className="hidden"
																			value="Add IP"
																			disabled
																		>
																			Add IP
																		</MenuItem>
																		<MenuItem
																			onClick={() => {
																				const newIPRanges = [
																					...modifiedRow.ip_ranges,
																				];
																				newIPRanges.push(['']);
																				setModifiedRow({
																					...modifiedRow,
																					ip_ranges: newIPRanges,
																				});
																				setErrTxts({
																					...errTxts,
																					ip_restriction: '',
																				});
																				isIPDataChanged.current = true;
																			}}
																		>
																			Single IP
																		</MenuItem>
																		<MenuItem
																			onClick={() => {
																				const newIPRanges = [
																					...modifiedRow.ip_ranges,
																				];
																				newIPRanges.push(['', '']);
																				setModifiedRow({
																					...modifiedRow,
																					ip_ranges: newIPRanges,
																				});
																				setErrTxts({
																					...errTxts,
																					ip_restriction: '',
																				});
																				isIPDataChanged.current = true;
																			}}
																		>
																			IP Range
																		</MenuItem>
																	</Select>
																</CustomButton>
															)}
														</div>
													</FormControl>
												</div>
												<div className="flex flex-col my-16 gap-64 lg:col-gap-136 lg">
													{!!modifiedRow.ip_restriction &&
														modifiedRow.ip_ranges?.map((ip, ind) => (
															// render a textfield for each ip
															<div className="grid grid-cols-3 gap-32" key={`ip-${ind}`}>
																<TextField
																	name={`ip-${ind}-0`}
																	label={`IP ${ind + 1}${
																		ip.length > 1 ? ' Start' : ''
																	}`}
																	error={!!errTxts[`ip_ranges.${ind}.0`]}
																	helperText={errTxts[`ip_ranges.${ind}.0`]}
																	value={modifiedRow.ip_ranges[ind][0]}
																	onChange={(e) => {
																		const newIPRanges = [...modifiedRow.ip_ranges];
																		newIPRanges[ind][0] = e.target.value;
																		setModifiedRow({
																			...modifiedRow,
																			ip_ranges: newIPRanges,
																		});
																		setErrTxts({
																			...errTxts,
																			[`ip_ranges.${ind}.0`]: '',
																		});
																	}}
																/>
																{ip.length > 1 && (
																	<TextField
																		name={`ip-${ind}-1`}
																		label={`IP ${ind + 1}${
																			ip.length > 1 ? ' End' : ''
																		}`}
																		error={!!errTxts[`ip_ranges.${ind}.1`]}
																		helperText={errTxts[`ip_ranges.${ind}.1`]}
																		value={modifiedRow.ip_ranges[ind][1]}
																		onChange={(e) => {
																			const newIPRanges = [
																				...modifiedRow.ip_ranges,
																			];
																			newIPRanges[ind][1] = e.target.value;
																			setModifiedRow({
																				...modifiedRow,
																				ip_ranges: newIPRanges,
																			});
																			setErrTxts({
																				...errTxts,
																				[`ip_ranges.${ind}.1`]: '',
																			});
																		}}
																	/>
																)}
																<i
																	className="fa fa-times cursor-pointer mt-20"
																	onClick={() => {
																		const newIPRanges = modifiedRow.ip_ranges || [];
																		newIPRanges.splice(ind, 1);
																		setModifiedRow({
																			...modifiedRow,
																			ip_ranges: newIPRanges,
																		});
																		setErrTxts({
																			...errTxts,
																			[`ip_ranges.${ind}.0`]: '',
																			[`ip_ranges.${ind}.1`]: '',
																		});
																	}}
																/>
															</div>
														))}
												</div>
											</div>
										</div>
									</fieldset>
									{isUpdating ? (
										<div className="flex align-center justify-center  my-32">
											<CircularProgress />
										</div>
									) : (
										<div className="flex justify-center my-32" style={{ gap: 20 }}>
											<CustomButton
												variant="secondary"
												width={140}
												onClick={() => {
													handleIPUpdate('CANCEL');
												}}
											>
												Cancel
											</CustomButton>
											<CustomButton
												width={140}
												variant="primary"
												onClick={() => {
													handleIPUpdate('SUBMIT');
												}}
											>
												Update
											</CustomButton>
										</div>
									)}
								</form>
							)}
						</div>
					</div>
				</div>
			</FuseScrollbars>
		</FuseAnimate>
	);
}

export default EditSchools;
