import { injectReducer } from 'app/store';
import React from 'react';

export default function withReducer(key, reducer) {
	return (WrappedComponent) =>
		class Wrapped extends React.PureComponent {
			constructor(props) {
				super(props);
				injectReducer(key, reducer);
			}

			render() {
				return <WrappedComponent {...this.props} />;
			}
		};
}
