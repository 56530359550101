import axios from 'axios';

const getFormula = () => {
	return axios.get('/api/v1/owner/stripe/distursment-formula');
};

const updateFormula = (data) => {
	return axios.patch('/api/v1/owner/stripe/distursment-formula?_method=patch', data);
};

const getSchoolsList = () => {
	return axios.get(`/api/v1/owner/management/school-list`);
};

const getStateList = (page) => {
	return axios.get(`api/v1/states?page=${page}`);
};

const getCompanyList = (search, city, state, page) => {
	return axios.get(`api/v1/owner/company/list?company=${search}&city=${city}&state=${state}&page=${page}`);
};

const getSchoolRoyalties = (company_id, page) => {
	return axios.get(`api/v1/owner/stripe/companies/royalities?company_id=${company_id}&page=${page}`);
};

const getpayment = (name, school_id, status, date_from, date_to, page) => {
	return axios.get(
		`api/v1/owner/stripe/payments?name=${name}&school_id=${school_id}&status=${status}&date_from=${date_from}&date_to=${date_to}&page=${page}`,
	);
};

const getschoollist = (companyId) => {
	return axios.get(`/api/v1/owner/schools?company_id=${companyId}`);
};

const getroyalties = (school_id, date_from, date_to, id) => {
	return axios.get(
		`api/v1/owner/stripe/companies/${id}/royalities?school_id=${school_id}&date_from=${date_from}&date_to=${date_to}`,
	);
};

const getSchoollistById = (companyId, search, page) => {
	return axios.get(`/api/v1/owner/companies/${companyId}/schools?search=${search}&page=${page}`);
};

export {
	getSchoolRoyalties,
	getroyalties,
	getFormula,
	updateFormula,
	getSchoolsList,
	getStateList,
	getCompanyList,
	getpayment,
	getschoollist,
	getSchoollistById,
};
