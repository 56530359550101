/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import history from '@history';
import {
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Paper,
	CircularProgress,
	IconButton,
	TextField,
	InputAdornment,
	Avatar,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { getCompanyList, getSearchableStateList } from 'app/services/Company/companyService';
import './Company.css';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { useDispatch } from 'react-redux';
import * as Actions from 'app/store/actions';
import InfiniteScroll from 'react-infinite-scroll-component';
import CustomButton from 'app/customComponents/CustomButton/CustomButton';
import CustomCheckbox from 'app/customComponents/CustomCheckbox/CustomCheckbox';
import { exportCompanyData } from 'app/services/reports/reports';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DisableConfirmDialog from './DisableConfirmDialog';

function Company() {
	const dispatch = useDispatch();
	const [rows, setRows] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [hasMore, setHasMore] = useState(false);
	const [firstLoad, setFirstLoad] = useState(true);
	const [fetchingMore, setFetchingMore] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [filters, setFilters] = useState({ search: '', city: '', state: '' });
	const [states, setStates] = useState([]);
	const [page, setPage] = useState(1);
	const [exportLoaders, setExportLoaders] = useState([]);
	const [isStateloading, setIsStateloading] = useState(false);
	const [searchStateQuery, setStateSearchQuery] = useState();

	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(
			() => {
				if (!isMounted) return;
				setIsStateloading(true);
				setStates([]);
				if (!searchStateQuery) {
					getSearchableStateList('', '')
						.then((res) => {
							if (!isMounted) return;
							setStates(res.data.data);
						})
						.catch((err) => {
							if (!isMounted) return;
							dispatch(
								Actions.showMessage({
									message: 'Failed to get states.',
									autoHideDuration: 1500,
									variant: 'error',
								}),
							);
						})
						.finally(() => {
							if (!isMounted) return;
							setIsStateloading(false);
						});
				} else {
					getSearchableStateList(searchStateQuery, searchStateQuery ? undefined : 1)
						.then((res) => {
							if (!isMounted) return;
							setStates(res.data.data);
						})
						.catch((err) => {
							if (!isMounted) return;
							dispatch(
								Actions.showMessage({
									message: 'Failed to get states.',
									autoHideDuration: 1500,
									variant: 'error',
								}),
							);
						})
						.finally(() => {
							if (!isMounted) return;
							setIsStateloading(false);
						});
				}
			},
			firstLoad ? 0 : 1000,
		);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
	}, [dispatch, searchStateQuery, filters]);

	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(
			() => {
				if (!isMounted) return;
				setIsLoading(true);
				getCompanyList(filters.search, filters.city, filters.state, 1)
					.then((res) => {
						if (!isMounted) return;
						setFirstLoad(false);
						setRows(res.data.data || []);
						setExportLoaders(res.data.data.map(() => false));
						setHasMore(res.data.to < res.data.total);
						setPage(res.data.current_page + 1);
					})
					.catch((err) => {
						if (!isMounted) return;
						dispatch(
							Actions.showMessage({
								message: 'Failed to fetch data, please refresh',
								variant: 'error',
							}),
						);
					})
					.finally(() => {
						if (!isMounted) return;
						setIsLoading(false);
					});
			},
			firstLoad ? 0 : 1000,
		);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
		// eslint-disable-next-line
	}, [refresh, filters]);

	const handleDisable = (ev, row) => {
		ev.preventDefault();
		dispatch(
			Actions.openDialog({
				children: <DisableConfirmDialog row={row} setRefresh={setRefresh} refresh={refresh} />,
			}),
		);
	};

	const handleLoadMore = () => {
		setFetchingMore(true);
		getCompanyList(filters.search, filters.city, filters.state, page)
			.then((res) => {
				if (res.data.last_page > res.data.current_page) {
					setHasMore(true);
				} else {
					setHasMore(false);
				}
				setPage(res.data.current_page + 1);
				setRows(rows.concat(res.data.data));
				setExportLoaders(exportLoaders.concat(res.data.data.map(() => false)));
				setFetchingMore(false);
			})
			.catch((err) => {
				setFetchingMore(false);
				dispatch(
					Actions.showMessage({
						message: 'Failed to fetch data, please refresh',
						variant: 'error',
					}),
				);
			});
	};

	const handleFilters = (ev) => {
		if (ev.target.name === 'search') {
			setFilters({ ...filters, search: ev.target.value });
		}

		if (ev.target.name === 'city') {
			if (ev.target.value) {
				setFilters({ ...filters, city: ev.target.value });
			} else {
				setFilters({ ...filters, city: '' });
			}
		}
		if (ev.target.name === 'state') {
			if (ev.target.value) {
				setFilters({ ...filters, state: ev.target.value });
			} else {
				setFilters({ ...filters, state: '' });
			}
		}
	};

	const goToAddCompany = () => {
		history.push({ pathname: '/AddCompany' });
	};

	const goToCompanyDetails = (row) => {
		history.push({ pathname: `/viewCompany/${row.id}` });
	};

	const exportCompany = (company, index) => {
		setExportLoaders(exportLoaders.map((item, i) => (i === index ? true : item)));
		exportCompanyData(company.id)
			.then((res) => {
				const link = document.createElement('a');
				link.href = res.data.downloadable_file;
				link.setAttribute('download', `${company.name}-data-export.zip`);
				document.body.appendChild(link);
				link.click();
				link.parentNode.removeChild(link);
			})
			.catch((err) => {
				dispatch(
					Actions.showMessage({
						message: 'Failed to export company data.',
						variant: 'error',
					}),
				);
			})
			.finally(() => {
				setExportLoaders(exportLoaders.map((item, i) => (i === index ? false : item)));
			});
	};

	return (
		<FuseAnimate animation="transition.slideLeftIn" duration={600}>
			<div className="mx-auto company-cont">
				<div className="flex items-center flex-nowrap justify-between">
					<h3 className=" mr-28" style={{ fontSize: '20px', fontWeight: '700' }}>
						Company
					</h3>{' '}
					<div className="flex justify-between">
						<div className="flex">
							<TextField
								name="search"
								className="mx-8"
								style={{ width: 170 }}
								id="search"
								value={filters.search}
								onChange={handleFilters}
								label="Search By Company"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												onClick={() => {
													document.getElementById('search').focus();
												}}
											>
												<img
													alt="search-icon"
													src="assets/images/search-icon.svg"
													height="80%"
													width="80%"
												/>
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
							<TextField
								name="city"
								className="mx-8"
								style={{ width: 150 }}
								id="city"
								value={filters.city}
								onChange={handleFilters}
								label="Search By City"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												onClick={() => {
													document.getElementById('city').focus();
												}}
											>
												<img
													alt="search-icon"
													src="assets/images/search-icon.svg"
													height="80%"
													width="80%"
												/>
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
							<div className="mx-8">
								{/* <FormControl>
									<InputLabel id="roomLabel">Filter By State</InputLabel>
									<Select
										name="state"
										onChange={handleFilters}
										value={filters.state}
										labelId="roomLabel"
										id="state"
										label="Filter by State"
										style={{ width: 150 }}
										endAdornment={
											filters.state ? (
												<IconButton size="small" className="mr-16">
													<Close
														onClick={() =>
															setFilters({
																...filters,
																state: ''
															})
														}
														fontSize="small"
													/>
												</IconButton>
											) : (
												''
											)
										}
									>
										<MenuItem className={`${!filters.state && 'hidden'}`} value={0}>
											Clear filter
										</MenuItem>
										{states.length ? (
											states.map(state => {
												return (
													<MenuItem key={state.id} value={state.id}>
														{state.name}
													</MenuItem>
												);
											})
										) : (
											<MenuItem disabled>Loading...</MenuItem>
										)}
									</Select>
								</FormControl> */}
								<Autocomplete
									id="state-autocomplete"
									className="mx-8"
									options={states}
									renderOption={(option) => (
										<>
											<div className="flex" style={{ gap: 10 }}>
												<div>{option.name}</div>
											</div>
										</>
									)}
									getOptionLabel={(option) => option.name}
									autoComplete={false}
									clearOnBlur={false}
									disableClearable
									loading={isStateloading}
									loadingText="...Loading"
									sx={{ width: '100%' }}
									onChange={(_e, v) => {
										setFilters({ ...filters, state: v?.id || '' });
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Filter by State"
											style={{ width: '160px' }}
											onChange={(e) => {
												setStateSearchQuery(e.target.value);
												if (e.target.value === '') {
													setFilters({ ...filters, state: '' });
												}
											}}
											autoComplete="off"
										/>
									)}
								/>
							</div>
						</div>
						<div className="self-end">
							<span>
								<span className="mx-4">
									<CustomButton
										variant="primary"
										height="40px"
										width="135px"
										fontSize="15px"
										onClick={goToAddCompany}
									>
										+ Add
									</CustomButton>
								</span>
							</span>
						</div>
					</div>
				</div>
				<TableContainer id="Scrollable-table" component={Paper} className="company-table-cont">
					<Table stickyHeader style={{ width: '100%' }}>
						<TableHead>
							<TableRow>
								<TableCell style={{ width: '20%' }} className="bg-white companyTableHeader">
									Company Name
								</TableCell>
								<TableCell style={{ width: '20%' }} className="bg-white companyTableHeader">
									Total No of Schools
								</TableCell>
								<TableCell style={{ width: '20%' }} className="bg-white companyTableHeader">
									Total Active Subscribers
								</TableCell>
								<TableCell style={{ width: '10%' }} className="bg-white companyTableHeader">
									Status
								</TableCell>
								<TableCell style={{ width: '20%' }} className="bg-white companyTableHeader">
									Action
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody className="">
							{isLoading ? (
								<TableRow>
									<TableCell align="center" colSpan={8}>
										<CircularProgress />
									</TableCell>
								</TableRow>
							) : !rows.length && !firstLoad ? (
								<TableRow>
									<TableCell align="center" colSpan={8}>
										No Company
									</TableCell>
								</TableRow>
							) : (
								rows?.map((row, index) => (
									<TableRow key={row.id}>
										<TableCell style={{ fontWeight: 700 }} component="th" scope="row">
											<div className="flex items-center">
												<Avatar className="mr-6" alt="company-face" src={row?.logo} />
												<div className="company-name truncate">{row?.name}</div>
											</div>
										</TableCell>
										<TableCell align="left">
											<div style={{ fontSize: 12, fontWeight: '700' }}>
												{row?.branches_count ? row?.branches_count : 0}
											</div>
										</TableCell>
										<TableCell align="left">
											<div style={{ fontSize: 12, fontWeight: '700' }}>
												{row?.total_subscribers ? row?.total_subscribers : 0}
											</div>
										</TableCell>
										<TableCell>
											<div
												style={{
													fontWeight: 'bold',
													fontSize: 12,
													color: `${row?.status === 1 ? '#04C01C' : '#FF4B4B'}`,
												}}
											>
												{row?.status === 1 ? 'Active' : 'Inactive'}
											</div>
										</TableCell>
										<TableCell className="action-cell">
											<span
												id={`view-details-${index}`}
												className="view-btn-wrapper"
												onClick={() => goToCompanyDetails(row)}
											>
												<button type="button" style={{ fontSize: 12 }}>
													View Details
												</button>
											</span>
											{exportLoaders[index] ? (
												<CircularProgress size={20} />
											) : (
												<img
													onClick={() => exportCompany(row, index)}
													src="assets/images/export.svg"
													width="30px"
													alt="export"
													className="cursor-pointer"
												/>
											)}
											<CustomCheckbox row={row} onClick={handleDisable} id={index} />
										</TableCell>
									</TableRow>
								))
							)}
							{fetchingMore ? (
								<TableRow>
									<TableCell align="center" colSpan={8}>
										<CircularProgress />
									</TableCell>
								</TableRow>
							) : (
								<></>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<InfiniteScroll
					dataLength={rows.length}
					next={handleLoadMore}
					hasMore={hasMore}
					scrollableTarget="Scrollable-table"
				/>
			</div>
		</FuseAnimate>
	);
}
export default Company;
