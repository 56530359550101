/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import './PaymentManagement.css';
import history from '@history';
import ViewPayment from './payment/Payment';
import RoyaltyInfo from './schoolRoyalties/RoyaltyInfo';

function Company() {
	const [activeTab, setActiveTab] = useState(0);
	if (history.location.state) {
		setActiveTab(0);
		delete history.location.state;
	}

	return (
		<>
			<div className="messaging-top-bar">
				<div className="flex mx-auto mr-30 ml-40" style={{ gap: 20 }}>
					<span
						onClick={() => setActiveTab(0)}
						className="cursor-pointer messaging-personal-hd text-1xl font-semibold"
					>
						<h1 className={`${activeTab === 0 ? 'messaging-active-hd' : ''} messaging-hd`}>Payment</h1>
					</span>
					<span
						onClick={() => setActiveTab(1)}
						className="cursor-pointer messaging-personal-hd text-1xl font-semibold"
					>
						<h1 className={`${activeTab === 1 ? 'messaging-active-hd' : ''} messaging-hd`}>
							School Royalties
						</h1>
					</span>
				</div>
			</div>
			{activeTab === 0 && <ViewPayment />}
			{activeTab === 1 && <RoyaltyInfo />}
		</>
	);
}
export default Company;
