import React from 'react';
import authRoles from 'app/auth/authRoles';
import UserManagement from './UserManagement';

const UserManagementConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: '/UserManagement',
			component: UserManagement,
		},
	],
	auth: authRoles.productOwner,
};

export default UserManagementConfig;
