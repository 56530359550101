import React from 'react';
import { Redirect } from 'react-router-dom';
import FuseUtils from '@fuse/utils';
import ProductOwnerConfig from 'app/main/productOwner/productOwnerConfig';
import pagesConfigs from 'app/main/pages/pagesConfigs';
// import UserMenuConfig from 'app/fuse-layouts/shared-components/UserMenuConfig';

const routeConfigs = [...pagesConfigs, ...ProductOwnerConfig];

const routes = [
	...FuseUtils.generateRoutesFromConfigs(routeConfigs),
	{
		path: '/',
		exact: true,
		component: () => <Redirect to="/login" />,
	},
	{
		path: '/*',
		component: () => <Redirect to="/404" />,
	},
];

export default routes;
