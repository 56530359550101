import axios from 'axios';

const getSchools = (
	name = '',
	type = '',
	schoolId = '',
	stateId = '',
	city = '',
	dateFrom = '',
	dateTo = '',
	page = '',
	isExport = false,
	forMap = 1,
) => {
	return axios.get(
		`${
			import.meta.env.VITE_API_REPORTS_ENDPOINT
		}api/v1/schools?name=${name}&type=${type}&school_id=${schoolId}&state_id=${stateId}&city=${city}&date_from=${dateFrom}&date_to=${dateTo}&page=${page}&export=${isExport}&forMap=${forMap}&timeZoneOffset=${new Date().getTimezoneOffset()}`,
	);
};

const getSchoolsList = () => {
	return axios.get(`/api/v1/owner/management/school-list`);
};

const getEnrolledParents = (export_id, name, school_id, state_id, city, dateFrom, dateTo, page) => {
	return axios.get(
		`${
			import.meta.env.VITE_API_REPORTS_ENDPOINT
		}api/v1/parent-enrollment?export=${export_id}&name=${name}&school_id=${school_id}&state=${state_id}&city=${city}&date_from=${dateFrom}&date_to=${dateTo}&page=${page}&timeZoneOffset=${new Date().getTimezoneOffset()}`,
	);
};

const getCityList = (state_id) => {
	return axios.get(`/api/v1/cities?state_id=${state_id}`);
};

const getRevenueInfo = (export_id, name, school_id, state_id, city, dateFrom, dateTo, revenue_id, page) => {
	return axios.get(
		`${
			import.meta.env.VITE_API_REPORTS_ENDPOINT
		}api/v1/parent-subscription?export=${export_id}&name=${name}&school_id=${school_id}&state=${state_id}&city=${city}&date_from=${dateFrom}&date_to=${dateTo}&revenue=${revenue_id}&page=${page}&timeZoneOffset=${new Date().getTimezoneOffset()}`,
	);
};

const exportCompanyData = (companyId) => {
	return axios.get(
		`${
			import.meta.env.VITE_API_REPORTS_ENDPOINT
		}api/v1/company-data?company_id=${companyId}&timeZoneOffset=${new Date().getTimezoneOffset()}`,
	);
};

const exportSchoolData = (schoolId) => {
	return axios.get(
		`${
			import.meta.env.VITE_API_REPORTS_ENDPOINT
		}api/v1/company-data?school_id=${schoolId}&timeZoneOffset=${new Date().getTimezoneOffset()}`,
	);
};

export {
	getSchools,
	getSchoolsList,
	getEnrolledParents,
	getRevenueInfo,
	getCityList,
	exportCompanyData,
	exportSchoolData,
};
