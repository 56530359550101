import React from 'react';
import authRoles from 'app/auth/authRoles';
import Notification from './Notification';
import AddNotification from './AddNotification/AddNotification';

const NotificationConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: '/Notifications',
			component: Notification,
		},
		{
			path: '/AddNotifications',
			component: AddNotification,
		},
	],
	auth: authRoles.productOwner,
};

export default NotificationConfig;
