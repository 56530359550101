import React, { useRef, useState, useEffect } from 'react';
import FuseAnimate from '@fuse/core/FuseAnimate';
import {
	makeStyles,
	Select,
	MenuItem,
	FormControl,
	TableContainer,
	Table,
	Paper,
	TableRow,
	TableCell,
	CircularProgress,
	TableBody,
} from '@material-ui/core';
import FusePageSimple from '@fuse/core/FusePageSimple';
import './AdminDashboard.css';

import { useDispatch } from 'react-redux';
import { getdashboarddata } from 'app/services/dashboard/dashBoard';
import * as Actions from 'app/store/actions';

import { Dashboard } from '@material-ui/icons';

const useStyles = makeStyles({
	layoutRoot: {},
	sidebar: {
		width: 330,
	},
});

function AdminDashboard() {
	const classes = useStyles();
	const pageLayout = useRef(null);
	// const [currentView, setCurrentView] = useState('week'); // One of week, day, month
	const dispatch = useDispatch();
	const [rows, setRows] = useState([]);

	const [hasMore, setHasMore] = useState(false);
	const [firstLoad, setFirstLoad] = useState(true);
	const [page, setPage] = useState(1);

	const [loading, setLoading] = useState(false);
	const [viewAll, setViewAll] = useState(false);
	const [totalRooms, setTotalRooms] = useState([]);

	const [filters, setFilters] = useState({ type: 'till_date' });
	const [dashBoard, setDashBoard] = useState({});
	const [fetchingMore, setFetchingMore] = useState(false);

	const handleView = (e) => {
		const { value } = e.target;
		setFilters({ type: value });
	};
	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(
			() => {
				if (!isMounted) return;
				setLoading(true);
				getdashboarddata(filters.type)
					.then((res) => {
						if (!isMounted) return;
						setFirstLoad(false);
						// setRows(res.data.data || []);
						// setHasMore(res.data.to < res.data.total);
						// setPage(res.data.current_page + 1);
						setDashBoard(res.data);
					})

					.catch((err) => {
						if (!isMounted) return;
						dispatch(
							Actions.showMessage({
								message: 'Failed to fetch data, please refresh',
								variant: 'error',
							}),
						);
					})
					.finally(() => {
						if (!isMounted) return;
						setLoading(false);
					});
			},
			firstLoad ? 0 : 1000,
		);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
		// // eslint-disable-next-line
	}, [filters.type]);

	return (
		<FusePageSimple
			classes={{
				root: classes.layoutRoot,
				sidebar: classes.sidebar,
			}}
			content={
				<div className="pl-72 pr-72 pt-52 pb-136">
					<FuseAnimate animation="transition.slideLeftIn" duration={600}>
						<>
							<div className="flex items-center flex-nowrap justify-between">
								<div className="">
									<h1 className="font-bold m-0" style={{ fontSize: '20px' }}>
										Welcome Admin!
									</h1>
									<h5 className="font-bold" style={{ color: '#6C757D', fontSize: '18px' }}>
										Dashboard
									</h5>
								</div>

								<div>
									<FormControl className="filter-class">
										<Select
											name="filter"
											onChange={handleView}
											value={filters.type}
											id="view"
											// className=''
											style={{ width: 150, gap: 10 }}
											startAdornment={
												<>
													{filters.type === 'daily' && (
														<img
															className="cursor-pointer"
															src="assets/images/day.png"
															alt="Day"
														/>
													)}
													{filters.type === 'weekly' && (
														<img
															className="cursor-pointer"
															src="assets/images/week.png"
															alt="Week"
														/>
													)}
													{filters.type === 'monthly' && (
														<img
															className="cursor-pointer"
															src="assets/images/month.png"
															alt="Month"
														/>
													)}
													{filters.type === 'till_date' && (
														<img
															className="cursor-pointer"
															src="assets/images/day.png"
															alt="Day"
														/>
													)}
												</>
											}
										>
											<MenuItem value="daily">
												<span id="day">Day</span>
											</MenuItem>
											<MenuItem value="weekly">
												<span id="week">Week</span>
											</MenuItem>
											<MenuItem value="monthly">
												<span id="month">Month</span>
											</MenuItem>
											<MenuItem value="till_date">
												<span id="month">Till Date</span>
											</MenuItem>
										</Select>
									</FormControl>
								</div>
							</div>
							<div className="flex flex-row mt-20 middle-div mt-32">
								{loading ? (
									<div className="flex-grow">
										<CircularProgress className="m-auto justtify-center items-center flex" />
									</div>
								) : (
									<div className="grid grid-cols-3 gap-64">
										<div className="w-full width-box flex flex-no-wrap gap-8">
											<div className="img-school">
												<img src="assets/images/Total-Schools.png" alt="Total Schools" />
											</div>

											<div className="dashboard-count">
												<div className="count-num text-lg xl:text-4xl">
													{dashBoard?.schools?.toLocaleString()}
												</div>
												<p className="count-hd">Total Schools</p>
											</div>
										</div>

										<div className="w-full width-box flex flex-no-wrap gap-8">
											<div className="img-school">
												<img src="assets/images/Revenue.png" alt="Revenue" />
											</div>

											<div className="dashboard-count">
												<div
													className="count-num text-lg xl:text-4xl"
													style={{ color: '#04C01C' }}
												>
													{/* show dashBoard.revenue as a locale string */}
													{dashBoard?.revenue?.toLocaleString('en-US', {
														style: 'currency',
														currency: 'USD',
													})}
												</div>
												<p className="count-hd  hd-renenue">Revenue</p>
											</div>
										</div>

										<div className="w-full width-box flex flex-no-wrap gap-8">
											<div className="img-school">
												<img src="assets/images/Activities.png" alt="Activities" />
											</div>

											<div className="dashboard-count">
												<div
													className="count-num text-lg xl:text-4xl"
													style={{ color: '#AA2FFF' }}
												>
													{dashBoard?.activities?.toLocaleString()}
												</div>
												<p className="count-hd">Total no of Activities</p>
											</div>
										</div>

										{Object.keys(dashBoard.package_status || {}).map((pkg, index) => {
											return (
												<div className="w-full width-box flex flex-no-wrap gap-8" key={pkg}>
													<div className="img-school">
														<img
															width={84}
															src={
																index == 0
																	? 'assets/images/Included_package.png'
																	: index == 1
																		? `assets/images/premium_package.png`
																		: `assets/images/ultimate_package.png`
															}
															alt="Package"
														/>
													</div>

													<div className="dashboard-count">
														<div
															className="count-num text-lg xl:text-4xl"
															style={{
																color:
																	index == 0
																		? '#4DA0ED'
																		: index == 1
																			? 'silver'
																			: 'gold',
															}}
														>
															{dashBoard[pkg]?.toLocaleString()}
														</div>
														<p className="count-hd">{pkg}</p>
													</div>
												</div>
											);
										})}

										<div className="w-full width-box flex flex-no-wrap gap-8">
											<div className="img-school">
												<img src="assets/images/Active.png" alt="Active" />
											</div>

											<div className="dashboard-count">
												<div
													className="count-num text-lg xl:text-4xl"
													style={{ color: '#E845F3' }}
												>
													{dashBoard?.active_subscribers?.toLocaleString()}
												</div>
												<p className="count-hd">Total no of Active Subscribers</p>
											</div>
										</div>

										<div className="w-full width-box flex flex-no-wrap gap-8">
											<div className="img-school">
												<img src="assets/images/Streaming.png" alt="Streaming" />
											</div>

											<div className="dashboard-count">
												<div
													className="count-num text-lg xl:text-4xl"
													style={{ color: '#FD9300' }}
												>
													{dashBoard?.live_streaming}%
												</div>
												<p className="count-hd">Session of Live Streaming</p>
											</div>
										</div>
									</div>
								)}
							</div>
						</>
					</FuseAnimate>
				</div>
			}
			innerScroll
			ref={pageLayout}
		/>
	);
}

export default AdminDashboard;
