/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FuseAnimate from '@fuse/core/FuseAnimate';
import {
	Button,
	Checkbox,
	CircularProgress,
	FormControl,
	FormHelperText,
	InputLabel,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@material-ui/core';
import history from '@history';
import './addnotification.css';
import CustomButton from 'app/customComponents/CustomButton/CustomButton';
import { useDispatch } from 'react-redux';
import * as Actions from 'app/store/actions';
import { getImageUrl } from 'utils/utils';
import {
	getCompanyList,
	getSchoolList,
	getParentList,
	sendNotification,
} from '../../../../services/notifications/notifications';
import { uploadFile } from 'app/services/imageUpload/imageUpload';

const useStyles = makeStyles({
	root: {
		color: 'white',
	},
	select: {
		'&:before': {
			borderBottom: 'none',
		},
		'&:after': {
			borderBottom: 'none',
		},
		'&:not(.Mui-disabled):hover::before': {
			borderBottom: 'none',
		},
		'& .MuiSelect-select:focus': {
			backgroundColor: 'inherit',
		},
	},
	icon: {
		fill: 'white',
	},
	formControl: {
		width: '300px',
	},
});
export default function AddNotification() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [errTxts, setErrTxts] = useState({});
	const [form, setForm] = useState({});
	const [isAdding, setIsAdding] = useState(false);
	const [receiver, setReceiver] = useState([{ id: 1, name: 'Company', value: 'company' }]);
	const [company, setCompany] = useState([]);
	const [allCompany, setAllCompany] = useState([]);
	const [school, setSchool] = useState([]);
	const [allSchool, setAllSchool] = useState([]);
	const [parents, setParents] = useState([]);
	const [allParents, setAllParents] = useState([]);
	const [preview, setPreview] = useState(null);
	const [selectedFile, setSelectedFile] = useState(null);

	const inputRef = useRef(null);

	const isAllCompany = allCompany.length > 0 && company.length === allCompany.length;
	const isAllSchool = allSchool.length > 0 && school.length === allSchool.length;
	const isAllparent = allParents.length > 0 && parents.length === allParents.length;

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250,
			},
		},
		getContentAnchorEl: null,
		anchorOrigin: {
			vertical: 'bottom',
			horizontal: 'center',
		},
		transformOrigin: {
			vertical: 'top',
			horizontal: 'center',
		},
		variant: 'menu',
	};

	useEffect(() => {
		let isMounted = true;
		getCompanyList()
			.then((res) => {
				if (!isMounted) return;
				setAllCompany(res.data);
			})
			.catch((err) => {
				if (!isMounted) return;
				dispatch(
					Actions.showMessage({
						message: 'Failed to fetch data, please refresh',
						variant: 'error',
					}),
				);
			});
		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		let isMounted = true;
		let companies = [];
		companies = company.map((item) => item.id);
		if (company.length > 0) {
			getSchoolList(companies)
				.then((res) => {
					if (!isMounted) return;
					setAllSchool(res.data);
					if (res.data.length == 0) {
						setSchool([]);
						setParents([]);
					}
				})
				.catch((err) => {
					if (!isMounted) return;
					dispatch(
						Actions.showMessage({
							message: 'Failed to fetch data, please refresh',
							variant: 'error',
						}),
					);
				});
		} else {
			setSchool([]);
		}
		return () => {
			isMounted = false;
		};
	}, [company]);

	useEffect(() => {
		let isMounted = true;
		let schools = [];
		schools = school.map((item) => item.id);
		if (school.length > 0) {
			getParentList(schools)
				.then((res) => {
					if (!isMounted) return;
					setAllParents(res.data);
					if (res.data.length == 0) {
						setParents([]);
					}
				})
				.catch((err) => {
					if (!isMounted) return;
					dispatch(
						Actions.showMessage({
							message: 'Failed to fetch data, please refresh',
							variant: 'error',
						}),
					);
				});
		} else {
			setParents([]);
		}
		return () => {
			isMounted = false;
		};
	}, [school]);

	useEffect(() => {
		if (!selectedFile) {
			setPreview(null);
			return;
		}
		const objectUrl = URL.createObjectURL(selectedFile);
		setPreview(objectUrl);
		return () => URL.revokeObjectURL(objectUrl);
	}, [selectedFile]);

	function handleFileUpload(e) {
		const file = e.target.files[0];

		if (
			file.type === 'application/pdf' ||
			file.type === 'image/jpeg' ||
			file.type === 'image/jpg' ||
			file.type === 'image/png'
		) {
			if (file.size > 5000000) {
				dispatch(Actions.showMessage({ message: 'File size must be less than 5 MB.', variant: 'error' }));
			} else {
				const data = {
					type: 'file',
				};
				if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
					const _URL = window.URL || window.webkitURL;
					const img = new Image();
					const objectUrl = _URL.createObjectURL(file);
					img.onload = () => {
						data.meta = {
							name: file.name,
							height: img.height,
							width: img.width,
							fileExtension: file.name.split('.')[file.name.split('.').length - 1],
						};
						_URL.revokeObjectURL(objectUrl);
					};
					img.src = objectUrl;
				} else {
					data.meta = {
						name: file.name,
						fileExtension: file.name.split('.')[file.name.split('.').length - 1],
					};
				}
			}
		}

		if (!file) {
			return;
		}
		const validExtensions = ['pdf', 'jpg', 'jpeg', 'png'];
		const fileExtension = file.name.split('.')[file.name.split('.').length - 1];
		const fileSize = 0.000001 * file.size;

		if (!validExtensions.includes(fileExtension)) {
			dispatch(
				Actions.showMessage({
					message: 'Only images or pdf files are allowed!',
					autoHideDuration: 2500,
					variant: 'error',
				}),
			);
			return;
		}
		if (fileSize > 5) {
			dispatch(
				Actions.showMessage({
					message: 'File size cannot exceed 5 MBs',
					autoHideDuration: 2500,
					variant: 'error',
				}),
			);
			return;
		}
		setSelectedFile(e.target.files[0]);
	}
	const notRec = [
		{ id: 1, name: 'Company', value: 'company' },
		{ id: 2, name: 'School', value: 'school' },
		{ id: 3, name: 'Parents', value: 'parent' },
	];

	const handleChange = (e, val) => {
		const { name, value } = e.target;
		setErrTxts({ ...errTxts, [name]: '' });

		if (name === 'receiver') {
			const setPer = val?.props?.value;
			const perfilter = receiver.some((item) => item.id === setPer?.id);

			if (!perfilter) {
				setReceiver((prev) => [...prev, setPer]);
			} else {
				setReceiver((prev) => prev.filter((item) => item.id !== setPer.id));
			}
		}
		if (name === 'company') {
			setCompany(value);
		}
		if (name === 'company' && value[value.length - 1] === 'all') {
			setCompany(company.length === allCompany.length ? [] : allCompany);
			if (value[value.length - 1] === 'all') {
				setSchool([]);
				setParents([]);
			}
		}
		if (name === 'school') {
			setSchool(value);
		}
		if (name === 'school' && value[value.length - 1] === 'all') {
			setSchool(school.length === allSchool.length ? [] : allSchool);
			if (value[value.length - 1] === 'all') {
				setParents([]);
			}
		}
		if (name === 'parents') {
			setParents(value);
		}
		if (name === 'parents' && value[value.length - 1] === 'all') {
			setParents(parents.length === allParents.length ? [] : allParents);
		}
		if (name === 'message') {
			setForm({ ...form, [name]: value });
		}
	};

	const handleSubmit = (ev) => {
		setErrTxts({});

		if (receiver?.length < 1) {
			setErrTxts({ ...errTxts, receiver: 'This field is required' });
			return;
		}
		if (company?.length < 1) {
			setErrTxts({ ...errTxts, company: 'This field is required' });
			return;
		}
		if (receiver?.some((item) => item?.value == 'school' || item?.value == 'parent') && school?.length < 1) {
			setErrTxts({ ...errTxts, school: 'This field is required' });
			return;
		}
		if (receiver?.some((item) => item?.value == 'parent') && parents?.length < 1) {
			setErrTxts({ ...errTxts, parents: 'This field is required' });
			return;
		}
		if (!form.message) {
			setErrTxts({ ...errTxts, message: 'This field is required' });
			return;
		}
		if (form.message.length > 500) {
			setErrTxts({ ...errTxts, message: 'Message cannot be more than 500 characters long!' });
			return;
		}
		form.receiver = receiver?.map((item) => item?.value);
		form.companies = company?.map((item) => item?.id);
		form.schools = school?.map((item) => item?.id);
		form.parents = parents?.map((item) => item?.parent_id);
		setIsAdding(true);

		if (
			selectedFile?.type === 'image/jpeg' ||
			selectedFile?.type === 'image/jpg' ||
			selectedFile?.type === 'image/png' ||
			selectedFile?.type === 'application/pdf'
		) {
			const filename = getImageUrl(selectedFile);
			const _URL = window.URL || window.webkitURL;
			const img = new Image();
			const objectUrl = _URL.createObjectURL(selectedFile);
			img.onload = () => {
				_URL.revokeObjectURL(objectUrl);
			};
			img.src = objectUrl;

			uploadFile(selectedFile, filename).then((response) => {
				const attachment = `${import.meta.env.VITE_S3_BASE_URL}${response}`;
				form.attachment = attachment;
				if (selectedFile.type === 'application/pdf') {
					form.attachment_type = 'pdf';
					sendNotification(form)
						.then((resp) => {
							dispatch(
								Actions.showMessage({
									message: 'Notification send successfully',
									autoHideDuration: 1500,
									variant: 'success',
								}),
							);
							history.goBack();
						})
						.catch((err) => {
							if (err.response?.data?.errors) {
								setErrTxts(err.response.data.errors);
							} else {
								dispatch(
									Actions.showMessage({
										message: 'Failed to send notification.',
										autoHideDuration: 1500,
										variant: 'error',
									}),
								);
							}
						})
						.finally(() => setIsAdding(false));
				} else {
					form.attachment_type = 'image';
					sendNotification(form)
						.then((resp) => {
							dispatch(
								Actions.showMessage({
									message: 'Notification send successfully',
									autoHideDuration: 1500,
									variant: 'success',
								}),
							);
							history.goBack();
						})
						.catch((err) => {
							if (err.response?.data?.errors) {
								setErrTxts(err.response.data.errors);
							} else {
								dispatch(
									Actions.showMessage({
										message: 'Failed to send notification.',
										autoHideDuration: 1500,
										variant: 'error',
									}),
								);
							}
						})
						.finally(() => setIsAdding(false));
				}
			});
		} else {
			setIsAdding(true);
			sendNotification(form)
				.then((resp) => {
					dispatch(
						Actions.showMessage({
							message: 'Notification send successfully',
							autoHideDuration: 1500,
							variant: 'success',
						}),
					);
					history.goBack();
				})
				.catch((err) => {
					if (err.response?.data?.errors) {
						setErrTxts(err.response.data.errors);
					} else {
						dispatch(
							Actions.showMessage({
								message: 'Failed to send notification.',
								autoHideDuration: 1500,
								variant: 'error',
							}),
						);
					}
				})
				.finally(() => setIsAdding(false));
		}
	};
	return (
		<FuseAnimate animation="transition.slideLeftIn" duration={600}>
			<div className="add-notification-div mx-auto">
				<div>
					<div className="flex gap-10">
						<h2 className="font-bold" style={{ fontSize: '20px' }}>
							<Button
								onClick={() => {
									history.goBack();
								}}
							>
								<img
									alt="Go Back"
									className="cursor-pointer"
									src="assets/images/arrow-long.png"
									style={{ width: '25px' }}
								/>
							</Button>
							Create Notifications
						</h2>
					</div>
					<div className="bg-white rounded-12 form-notification-main-div">
						<div className="form-notification-inner-div">
							<div
								className="bg-white rounded mx-auto"
								// style={{ paddingBottom: '70px' }}
							>
								<div className="main-notification-drop">
									<FormControl
										error={!!errTxts.receiver?.length}
										className={`${classes.formControl}`}
									>
										<InputLabel id="mutiple-select-label">Notification Receiver</InputLabel>
										<Select
											labelId="mutiple-select-label"
											multiple
											value={receiver}
											error={!!errTxts.receiver?.length}
											name="receiver"
											id="receiver"
											onChange={handleChange}
											renderValue={(selected) => selected?.map((item) => item.name).join(', ')}
											MenuProps={MenuProps}
										>
											{notRec.length ? (
												notRec.map((role) => {
													return (
														<MenuItem key={role.value} value={role}>
															<ListItemIcon>
																<Checkbox
																	checked={
																		receiver
																			?.map((ro) => ro?.id)
																			.indexOf(role?.id) > -1
																	}
																/>
															</ListItemIcon>
															<ListItemText primary={role?.name} />
														</MenuItem>
													);
												})
											) : (
												<MenuItem disabled>Loading...</MenuItem>
											)}
										</Select>
										{!!errTxts.receiver && <FormHelperText>{errTxts.receiver}</FormHelperText>}
									</FormControl>
									<div className="alldrop-notification">
										{receiver?.some((item) => item?.value == 'company') ||
										receiver?.some((item) => item?.value == 'school') ||
										receiver?.some((item) => item?.value == 'parent') ? (
											<FormControl
												error={!!errTxts.company?.length}
												className={`${classes.formControl}`}
											>
												<InputLabel id="mutiple-select-label">Company</InputLabel>
												<Select
													labelId="mutiple-select-label"
													multiple
													value={company}
													error={!!errTxts.company?.length}
													name="company"
													id="company"
													onChange={handleChange}
													renderValue={(selected) =>
														selected?.map((item) => item?.name).join(', ')
													}
													MenuProps={MenuProps}
												>
													{allCompany.length > 0 ? (
														<MenuItem
															value="all"
															classes={{
																root: isAllCompany ? classes.selectedAll : '',
															}}
														>
															<ListItemIcon>
																<Checkbox
																	classes={{
																		indeterminate: classes.indeterminateColor,
																	}}
																	checked={isAllCompany}
																	indeterminate={
																		company.length > 0 &&
																		company.length < allCompany.length
																	}
																/>
															</ListItemIcon>
															<ListItemText
																classes={{ primary: classes.selectAllText }}
																primary="Select All"
															/>
														</MenuItem>
													) : null}

													{allCompany?.length ? (
														allCompany?.map((role) => {
															return (
																<MenuItem key={role.id} value={role}>
																	<ListItemIcon id={`roles-${role?.id}`}>
																		<Checkbox
																			checked={
																				company
																					?.map((ro) => ro?.id)
																					.indexOf(role?.id) > -1
																			}
																		/>
																	</ListItemIcon>
																	<ListItemText
																		primary={role?.name.split('_').join(' ')}
																		primaryTypographyProps={{
																			style: {
																				whiteSpace: 'nowrap',
																				overflow: 'hidden',
																				textOverflow: 'ellipsis',
																			},
																		}}
																	/>
																</MenuItem>
															);
														})
													) : (
														<MenuItem disabled>Loading...</MenuItem>
													)}
												</Select>
												{!!errTxts.company && (
													<FormHelperText>{errTxts.company}</FormHelperText>
												)}
											</FormControl>
										) : null}

										{receiver?.some((item) => item?.value == 'school') ||
										receiver?.some((item) => item?.value == 'parent') ? (
											<FormControl
												error={!!errTxts.school?.length}
												className={`${classes.formControl}`}
											>
												<InputLabel id="mutiple-select-label">School</InputLabel>
												<Select
													labelId="mutiple-select-label"
													multiple
													value={school}
													error={!!errTxts.school?.length}
													name="school"
													id="school"
													onChange={handleChange}
													renderValue={(selected) =>
														selected?.map((item) => item?.name).join(', ')
													}
													MenuProps={MenuProps}
													disabled={company.length < 1}
												>
													{allSchool.length > 0 ? (
														<MenuItem
															value="all"
															classes={{
																root: isAllSchool ? classes.selectedAll : '',
															}}
														>
															<ListItemIcon id="all-roles">
																<Checkbox
																	classes={{
																		indeterminate: classes.indeterminateColor,
																	}}
																	checked={isAllSchool}
																	indeterminate={
																		school.length > 0 &&
																		school.length < allSchool.length
																	}
																/>
															</ListItemIcon>
															<ListItemText
																classes={{ primary: classes.selectAllText }}
																primary="Select All"
															/>
														</MenuItem>
													) : null}

													{allSchool.length ? (
														allSchool.map((role) => {
															return (
																<MenuItem key={role.id} value={role}>
																	<ListItemIcon id={`roles-${role?.id}`}>
																		<Checkbox
																			checked={
																				school
																					?.map((ro) => ro?.id)
																					.indexOf(role?.id) > -1
																			}
																		/>
																	</ListItemIcon>
																	<ListItemText
																		primary={role?.name.split('_').join(' ')}
																		primaryTypographyProps={{
																			style: {
																				whiteSpace: 'nowrap',
																				overflow: 'hidden',
																				textOverflow: 'ellipsis',
																			},
																		}}
																	/>
																</MenuItem>
															);
														})
													) : (
														<MenuItem disabled>Loading...</MenuItem>
													)}
												</Select>
												{!!errTxts.school && <FormHelperText>{errTxts.school}</FormHelperText>}
											</FormControl>
										) : null}

										{receiver?.some((item) => item?.value == 'parent') ? (
											<FormControl
												error={!!errTxts.parents?.length}
												className={`${classes.formControl}`}
											>
												<InputLabel id="mutiple-select-label">Parent</InputLabel>
												<Select
													labelId="mutiple-select-label"
													multiple
													value={parents}
													error={!!errTxts.parents?.length}
													name="parents"
													id="parents"
													onChange={handleChange}
													renderValue={(selected) =>
														selected
															?.map((item) => `${item?.first_name} ${item?.last_name}`)
															.join(', ')
													}
													MenuProps={MenuProps}
													disabled={company.length < 1 || school.length < 1}
												>
													{allParents.length > 0 ? (
														<MenuItem
															value="all"
															classes={{
																root: isAllparent ? classes.selectedAll : '',
															}}
														>
															<ListItemIcon id="all-roles">
																<Checkbox
																	classes={{
																		indeterminate: classes.indeterminateColor,
																	}}
																	checked={isAllparent}
																	indeterminate={
																		parents.length > 0 &&
																		parents.length < allParents.length
																	}
																/>
															</ListItemIcon>
															<ListItemText
																classes={{ primary: classes.selectAllText }}
																primary="Select All"
															/>
														</MenuItem>
													) : null}

													{allParents.length ? (
														allParents.map((role) => {
															return (
																<MenuItem key={role.parent_id} value={role}>
																	<ListItemIcon>
																		<Checkbox
																			checked={
																				parents
																					?.map((ro) => ro?.parent_id)
																					.indexOf(role?.parent_id) > -1
																			}
																		/>
																	</ListItemIcon>
																	<ListItemText
																		primary={`${role?.first_name} ${role?.last_name}`}
																		primaryTypographyProps={{
																			style: {
																				whiteSpace: 'nowrap',
																				overflow: 'hidden',
																				textOverflow: 'ellipsis',
																			},
																		}}
																	/>
																</MenuItem>
															);
														})
													) : (
														<MenuItem disabled>Loading...</MenuItem>
													)}
												</Select>
												{!!errTxts.parents && (
													<FormHelperText>{errTxts.parents}</FormHelperText>
												)}
											</FormControl>
										) : null}
									</div>
									<div className="message-div">
										<TextField
											style={{ width: '100%' }}
											id="outlined-multiline-static"
											label="Message"
											name="message"
											value={form.message}
											multiline
											onChange={handleChange}
											error={!!errTxts.message}
											helperText={errTxts.message}
										/>
									</div>
									<div className="upload-img">
										<Typography className="upload-img-heading">Upload File</Typography>
										{preview ? (
											<>
												{selectedFile &&
												selectedFile.name.split('.')[
													selectedFile.name.split('.').length - 1
												] !== 'pdf' ? (
													<>
														<img
															src={preview}
															style={{
																width: '87px',
																height: '84px',
																objectFit: 'cover',
															}}
															alt="File"
														/>
														<div
															className="crossIcon crossIcon-announcement-img"
															onClick={() => setSelectedFile(null)}
														>
															x
														</div>
													</>
												) : (
													<>
														<img
															src="assets/images/pdf_thumbnail.svg"
															style={{ width: '87px', height: '84px' }}
															alt="PDF"
														/>
														<div
															className="crossIcon crossIcon-announcement-pdf"
															onClick={() => setSelectedFile(null)}
														>
															x
														</div>
													</>
												)}
											</>
										) : (
											<>
												{' '}
												<img
													src="assets/images/upload-file.png"
													alt="Upload file"
													style={{ cursor: 'pointer' }}
													onClick={() => inputRef.current.click()}
												/>
												<input
													ref={inputRef}
													style={{ display: 'none' }}
													onChange={handleFileUpload}
													type="file"
													name="file"
													accept=".jpg, .png, .jpeg, .pdf"
												/>
											</>
										)}
									</div>
								</div>
							</div>
							<div className="flex justify-center w-max mt-40" style={{ gap: '20px' }}>
								{isAdding ? (
									<div className="flex justify-center">
										<CircularProgress className="mx-auto" />
									</div>
								) : (
									<>
										<CustomButton
											variant="secondary"
											width={140}
											onClick={() => {
												history.goBack();
											}}
										>
											Cancel
										</CustomButton>
										<CustomButton
											variant="primary"
											width={140}
											onClick={() => {
												handleSubmit();
											}}
										>
											Send
										</CustomButton>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</FuseAnimate>
	);
}
