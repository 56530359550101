/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import './Packages.css';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { InputAdornment, IconButton, TextField, CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import * as Actions from 'app/store/actions';
import { getPackages } from 'app/services/Packages/packages';
import history from '@history';

function Packages() {
	const dispatch = useDispatch();
	const [packages, setPackages] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [filters, setFilters] = useState({ search: '' });
	const [firstLoad, setFirstLoad] = useState(true);

	const handleFilters = (ev) => {
		if (ev.target.name === 'search') {
			setFilters({ ...filters, search: ev.target.value });
		}
	};

	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(
			() => {
				if (!isMounted) return;
				setIsLoading(true);
				getPackages(filters.search)
					.then((res) => {
						if (!isMounted) return;
						setFirstLoad(false);
						setPackages(res.data);
					})
					.catch((err) => {
						if (!isMounted) return;
						dispatch(
							Actions.showMessage({
								message: 'Failed to fetch data, please refresh',
								variant: 'error',
							}),
						);
					})
					.finally(() => {
						if (!isMounted) return;
						setIsLoading(false);
					});
			},
			firstLoad ? 0 : 1000,
		);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
		// eslint-disable-next-line
	}, [filters]);

	const goToEditPackage = (p) => {
		history.push({ pathname: `/PackageEdit/${p.id}` });
	};

	const NoPackages = () => {
		dispatch(
			Actions.showMessage({
				message: 'No Result Found',
				variant: 'error',
			}),
		);
	};

	return (
		<FuseAnimate animation="transition.slideLeftIn" duration={600}>
			<div className="package-main-page mx-auto">
				<div className="flex justify-between">
					<div>
						<h1 className="font-bold" style={{ fontSize: '20px' }}>
							View your packages{' '}
						</h1>
						<p>View all the package’s information here. </p>
					</div>

					<div className="flex">
						<TextField
							name="search"
							className="mx-8"
							style={{ width: 200, marginTop: 20 }}
							id="search"
							label="Search By Package"
							value={filters.search}
							onChange={handleFilters}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											onClick={() => {
												document.getElementById('search').focus();
											}}
										>
											<img
												alt="search-icon"
												src="assets/images/search-icon.svg"
												height="80%"
												width="80%"
											/>
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</div>
				</div>
				<div className="main-carousel-div">
					{isLoading ? (
						<div className="flex align-center justify-center">
							<CircularProgress />
						</div>
					) : !packages.length && !firstLoad ? (
						<div>{NoPackages()}</div>
					) : (
						<div className="flex scroll-div" style={{ gap: 30 }}>
							{packages?.map((p, i) => (
								<div key={i} className="bg-white rounded inner-box">
									<div className="flex justify-between item-center">
										<span />
										<img
											className=" cursor-pointer"
											alt="edit-icon"
											src="assets/images/circle-edit.png"
											height="30px"
											width="30px"
											onClick={() => goToEditPackage(p)}
										/>
									</div>
									<div className="package-img">
										<img
											className="mr-6"
											alt="company-face"
											height="30%"
											width="30%"
											src={p?.image}
										/>
									</div>
									<div className="pakage-price">
										${p?.price}/{p?.duration}
									</div>
									<div className="package-title">{p?.title}</div>
									{p?.features_meta?.map((f, i) => {
										return (
											<div key={i} className="flex flex-col package-features">
												<span dangerouslySetInnerHTML={{ __html: f?.value }} />
											</div>
										);
									})}
								</div>
							))}
						</div>
					)}
				</div>
			</div>
		</FuseAnimate>
	);
}
export default Packages;
