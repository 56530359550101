/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useDispatch } from 'react-redux';
import { Avatar } from '@material-ui/core';
import * as Actions from 'app/store/actions';

function ViewAllChildren({ row }) {
	const dispatch = useDispatch();
	return (
		<div className="bg-white student-list-card">
			<div className="flex justify-between student-list-header">
				<div>
					<h1 className="font-20px weight-70px">Students</h1>
				</div>
				<div>
					<i className="fas fa-times cursor-pointer" onClick={() => dispatch(Actions.closeDialog())} />
				</div>
			</div>
			<div id="scrollable-list" className="student-list-cont w-full">
				{row.family_child_all.slice(2).map((child, i) => (
					<div className="flex items-center pad-14" key={i}>
						<Avatar className="mr-6" alt="student-face" src={child.thumb} />
						<div className="flex flex-col items-start">
							<div className="student-name truncate break-all">
								{child.first_name} {child.last_name}
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default ViewAllChildren;
