import React from 'react';
import authRoles from '../../../auth/authRoles';
import ProductOwnerProfile from '../../../fuse-layouts/shared-components/adminDetail';
import Editprofile from '../../../fuse-layouts/shared-components/Editprofile';

const SuperAdminProfileConfig = {
	settings: {
		layout: {
			Config: {},
		},
	},
	routes: [
		{
			path: '/productOwnerprofile',
			component: ProductOwnerProfile,
		},
		{
			path: '/Editprofile',
			component: Editprofile,
		},
	],
	auth: authRoles.admin,
};

export default SuperAdminProfileConfig;
