import React from 'react';
import authRoles from 'app/auth/authRoles';
import Finance from './Finance';

const FinanceConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: '/Finance',
			component: Finance,
		},
	],
	auth: authRoles.productOwner,
};

export default FinanceConfig;
