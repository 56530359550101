import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Actions from 'app/store/actions';
import './DisableConfirmDialog.css';
import { updateSchoolStatus } from 'app/services/Company/companyService';
import { CircularProgress } from '@material-ui/core';

function DisableSchooConfirmDialog({ row, setRefresh, refresh }) {
	const [isRequesting, setIsRequesting] = useState(false);
	const dispatch = useDispatch();
	return (
		<div className="popup">
			<div>
				<div className="icon-span">
					<h2>!</h2>
				</div>
				<h1 className="disable-text">Are you sure you want to {row.status ? 'disable ' : 'enable'} it?</h1>
				{isRequesting ? (
					<CircularProgress />
				) : (
					<>
						<button
							type="button"
							className="no-disable-btn"
							onClick={() => dispatch(Actions.closeDialog())}
						>
							No
						</button>
						<button
							type="button"
							className="no-disable-btn"
							onClick={() => {
								setIsRequesting(true);
								updateSchoolStatus(row.id)
									.then(() => {
										dispatch(
											Actions.showMessage({
												message: row.status
													? `${row.name}  is disabled successfully from ladybird academy`
													: `${row.name}  is enabled successfully from ladybird academy`,
												autoHideDuration: 1500,
												variant: 'success',
											}),
										);
										setRefresh(!refresh);
										dispatch(Actions.closeDialog());
									})
									.catch((err) => {
										dispatch(
											Actions.showMessage({
												message:
													err?.response?.data?.message || 'Failed to update school status',
												variant: 'error',
												autoHideDuration: 2000,
											}),
										);
										dispatch(Actions.closeDialog());
									})
									.finally(() => {
										setIsRequesting(false);
									});
							}}
						>
							Yes
						</button>
					</>
				)}
			</div>
		</div>
	);
}

export default DisableSchooConfirmDialog;
