/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import history from '@history';
import {
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Paper,
	CircularProgress,
	IconButton,
	Checkbox,
	TextField,
	InputAdornment,
	InputLabel,
	ListItemIcon,
	ListItemText,
	Select,
	MenuItem,
	FormControl,
	Tooltip,
} from '@material-ui/core';
import './Notification.css';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import CustomButton from 'app/customComponents/CustomButton/CustomButton';
import CustomCheckbox from 'app/customComponents/CustomCheckbox/CustomCheckbox';
import * as Actions from 'app/store/actions';
import { getNotificationmessage } from '../../../services/notifications/notifications';
import dayjs from 'dayjs';
import moment from 'moment';
// import AttachmentModal from './AttachmentModal';
import axios from 'axios';
import * as DialogActions from 'app/store/actions/fuse/dialog.actions';

function Company() {
	const dispatch = useDispatch();
	const [rows, setRows] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [hasMore, setHasMore] = useState(false);
	const [firstLoad, setFirstLoad] = useState(true);
	const [fetchingMore, setFetchingMore] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [filters, setFilters] = useState({ company: '', school: '', parent: '', message: '' });
	const [page, setPage] = useState(1);
	const [receiver, setReceiver] = useState([
		// { id: 1, name: 'Company', value: '1' },
		// { id: 2, name: 'School', value: '1' },
		// { id: 3, name: 'Parents', value: '1' }
	]);
	const notRec = [
		{ id: 1, name: 'Company', value: '1' },
		{ id: 2, name: 'School', value: '1' },
		{ id: 3, name: 'Parents', value: '1' },
	];

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250,
			},
		},
		getContentAnchorEl: null,
		anchorOrigin: {
			vertical: 'bottom',
			horizontal: 'center',
		},
		transformOrigin: {
			vertical: 'top',
			horizontal: 'center',
		},
		variant: 'menu',
	};

	const goToAddNotification = () => {
		history.push('/AddNotifications');
	};

	const downloadPDF = (attachment) => {
		axios.get(attachment.link, { responseType: 'blob', headers: { Authorization: '' } }).then((res) => {
			const src = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
			const link = document.createElement('a');
			link.setAttribute('target', '_blank');
			link.href = src;
			// link.setAttribute('download', `download.pdf`);
			document.body.appendChild(link);
			link.click();
			// Clean up and remove the link
			link.parentNode.removeChild(link);
		});
	};

	const openImage = (attachment) => {
		dispatch(
			DialogActions.openDialog({
				children: (
					<>
						<div className="bg-white">
							<img src={attachment.link} alt="" />
						</div>
					</>
				),
			}),
		);
	};

	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(
			() => {
				if (!isMounted) return;
				setIsLoading(true);
				getNotificationmessage(
					receiver.filter((r) => r.name === 'Company').length ? 1 : 0,
					receiver.filter((r) => r.name === 'School').length ? 1 : 0,
					receiver.filter((r) => r.name === 'Parents').length ? 1 : 0,
					filters.message,
					1,
				)
					.then((res) => {
						if (!isMounted) return;
						setFirstLoad(false);
						setRows(res.data.data || []);
						setHasMore(res.data.to < res.data.total);
						setPage(res.data.current_page + 1);
					})

					.catch((err) => {
						if (!isMounted) return;
						dispatch(
							Actions.showMessage({
								message: 'Failed to fetch data, please refresh',
								variant: 'error',
							}),
						);
					})
					.finally(() => {
						if (!isMounted) return;
						setIsLoading(false);
					});
			},
			firstLoad ? 0 : 1000,
		);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
		// // eslint-disable-next-line
	}, [filters, receiver]);

	const handleLoadMore = () => {
		setFetchingMore(true);
		getNotificationmessage(
			receiver.filter((r) => r.name === 'Company').length ? 1 : 0,
			receiver.filter((r) => r.name === 'School').length ? 1 : 0,
			receiver.filter((r) => r.name === 'Parents').length ? 1 : 0,
			filters.message,
			page,
		)
			.then((res) => {
				if (res.data.last_page > res.data.current_page) {
					setHasMore(true);
				} else {
					setHasMore(false);
				}
				setPage(res.data.current_page + 1);
				setRows(rows.concat(res.data.data));
				setFetchingMore(false);
			})
			.catch((err) => {
				setFetchingMore(false);
				dispatch(
					Actions.showMessage({
						message: 'Failed to fetch data, please refresh',
						variant: 'error',
					}),
				);
			});
	};

	const handleFilters = (ev, val) => {
		const { name, value } = ev.target;
		if (ev.target.name === 'message') {
			setFilters({ ...filters, message: ev.target.value });
		}

		if (name === 'receiver') {
			const setPer = val?.props?.value;
			const perfilter = receiver.some((item) => item.id === setPer?.id);
			console.log(receiver, setPer, perfilter);

			if (!perfilter) {
				setReceiver((prev) => [...prev, setPer]);
			} else {
				setReceiver((prev) => prev.filter((item) => item.id !== setPer.id));
			}
		}
		// console.log(selected);
	};

	return (
		<>
			<FuseAnimate animation="transition.slideLeftIn" duration={600}>
				<div className="mx-auto company-cont">
					<div className="flex items-center flex-nowrap justify-between">
						<h3 className="font-bold mr-28" style={{ fontSize: '20px' }}>
							Notification
						</h3>{' '}
						<div className="flex justify-between">
							<div className="flex">
								<TextField
									name="message"
									className="mx-8"
									style={{ width: 180 }}
									id="message"
									value={filters.message}
									onChange={handleFilters}
									label="Search"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													onClick={() => {
														document.getElementById('message').focus();
													}}
												>
													<img
														alt="search-icon"
														src="assets/images/search-icon.svg"
														height="80%"
														width="80%"
													/>
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
								<div className="mx-8">
									<FormControl>
										<InputLabel id="mutiple-select-label">Filter by Type</InputLabel>
										<Select
											labelId="mutiple-select-label"
											multiple
											value={receiver}
											name="receiver"
											id="receiver"
											style={{ width: 180 }}
											onChange={handleFilters}
											renderValue={(selected) => selected?.map((item) => item.name).join(', ')}
											MenuProps={MenuProps}
										>
											{notRec.length ? (
												notRec.map((role) => {
													return (
														<MenuItem key={role.value} value={role}>
															<ListItemIcon>
																<Checkbox
																	checked={
																		receiver
																			?.map((ro) => ro?.id)
																			.indexOf(role?.id) > -1
																	}
																/>
															</ListItemIcon>
															<ListItemText primary={role?.name} />
														</MenuItem>
													);
												})
											) : (
												<MenuItem disabled>Loading...</MenuItem>
											)}
										</Select>
									</FormControl>
								</div>
							</div>
							<div className="self-end">
								<span>
									<span className="mx-4">
										<CustomButton
											variant="primary"
											height="40px"
											width="135px"
											fontSize="15px"
											onClick={goToAddNotification}
										>
											+ Create
										</CustomButton>
									</span>
								</span>
							</div>
						</div>
					</div>
					<TableContainer id="Scrollable-table" component={Paper} className="company-table-cont">
						<Table stickyHeader className="company-table" style={{ width: '100%' }}>
							<TableHead>
								<TableRow>
									<TableCell style={{ width: '25%' }} className="bg-white companyTableHeader">
										Recipient
									</TableCell>
									<TableCell style={{ width: '25%' }} className="bg-white companyTableHeader">
										Message
									</TableCell>
									<TableCell style={{ width: '25%' }} className="bg-white companyTableHeader">
										Attachment
									</TableCell>
									<TableCell style={{ width: '25%' }} className="bg-white companyTableHeader">
										Date
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody className="">
								{isLoading ? (
									<TableRow>
										<TableCell align="center" colSpan={8}>
											<CircularProgress />
										</TableCell>
									</TableRow>
								) : !rows.length && !firstLoad ? (
									<TableRow>
										<TableCell align="center" colSpan={8}>
											No record found
										</TableCell>
									</TableRow>
								) : (
									rows?.map((row) => {
										const formattedReceiver = row.data.receiver
											.replace(/,\s*/g, ', ')
											.replace(/,\s*(\w)/g, (_, letter) => `, ${letter.toUpperCase()}`);

										return (
											<TableRow key={row.id}>
												<TableCell style={{ fontWeight: 700 }} component="th" scope="row">
													<div className="flex items-center">
														<div className="company-name truncate">
															{/* {row.data.parent_id && 'Parent, '} */}
															{/* {(row.data.school_id || row.data.parent_id) && ', '} */}
															{/* {row.data.company_id && 'Company'}
														{row.data.school_id && ', '}
														{row.data.school_id && 'School'} */}
															{formattedReceiver}
														</div>
													</div>
												</TableCell>
												<TableCell
													style={{ fontWeight: '700', fontSize: '12px', color: '#06071d' }}
													component="th"
													scope="row"
												>
													{/* <p>{row.data.body}</p> */}

													<p>
														{' '}
														{row.data.body ? (
															row.data.body.length > 26 ? (
																<Tooltip
																	title={` ${row.data.body ? row.data.body : ''}`}
																	arrow
																>
																	<span>
																		{` ${
																			row.data.body?.slice(0, 26)
																				? row.data.body?.slice(0, 26)
																				: ''
																		} .....`}
																	</span>
																</Tooltip>
															) : (
																<span>{row.data.body}</span>
															)
														) : (
															'-'
														)}
													</p>
												</TableCell>
												<TableCell style={{ fontWeight: 700 }} component="th" scope="row">
													<div className="flex items-center">
														<div className="company-name truncate">
															<div className="w-1/4 relative">
																{row.data.attachments?.length ? (
																	<a
																		// href={attachment.file}
																		target="_blank"
																		rel="noopener noreferrer"
																	>
																		{row.data.attachments[0]?.type === 'image' ? (
																			<img
																				style={{ width: '100px' }}
																				src={row.data.attachments[0].link}
																				className="cursor-pointer mx-auto"
																				alt="file"
																				onClick={() =>
																					openImage(row.data.attachments[0])
																				}
																			/>
																		) : (
																			<img
																				style={{ width: '100px' }}
																				src="assets/images/pdf-icon.png"
																				className="cursor-pointer mx-auto"
																				alt="file"
																				onClick={() =>
																					downloadPDF(row.data.attachments[0])
																				}
																			/>
																		)}
																	</a>
																) : (
																	'-'
																)}

																{/* <div
																		className="remove-attachment"
																		onClick={() =>
																			handleRemoveAttachment(attachment.id)
																		}
																	>
																		x
																	</div> */}
															</div>
														</div>
													</div>
												</TableCell>
												<TableCell style={{ fontWeight: 700 }} component="th" scope="row">
													<div className="flex items-center">
														<div className="company-name truncate">
															{/* {row?.created_at ? (
															<>
																{dayjs(`${row?.created_at}z`).format(
																	'MMMM[ ] D[,] YYYY[, ] hh:mm A'
																)}

															</>
														) : (
															'-'
														)} */}

															<div className="flex flex-col">
																<div
																	style={{
																		fontSize: '12px',
																		fontWeight: '600',
																		color: '#000',
																	}}
																>
																	{moment.utc(row?.created_at).local().format('L')}
																</div>
																<div className="subscribe-date">
																	{moment.utc(row?.created_at).local().format('LT')}
																</div>
															</div>
														</div>
													</div>
												</TableCell>
											</TableRow>
										);
									})
								)}
								{fetchingMore ? (
									<TableRow>
										<TableCell align="center" colSpan={8}>
											<CircularProgress />
										</TableCell>
									</TableRow>
								) : (
									<></>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<InfiniteScroll
						dataLength={rows.length}
						next={handleLoadMore}
						hasMore={hasMore}
						scrollableTarget="Scrollable-table"
					/>
				</div>
			</FuseAnimate>
		</>
	);
}
export default Company;
