import React from 'react';
import authRoles from 'app/auth/authRoles';
import PaymentManagement from './PaymentManagement';
import CompanyDetail from './schoolRoyalties/CompanyDetail';
import SchoolRoyalties from './schoolRoyalties/SchoolRoyalties';
import RoyaltyInfo from './schoolRoyalties/RoyaltyInfo';

const PaymentManagementConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: '/PaymentManagement',
			component: PaymentManagement,
		},
		// {
		// 	path: '/CompanyDetail/:id',
		// 	component: CompanyDetail
		// },
		// {
		// 	path: '/SchoolRoyalties',
		// 	component: SchoolRoyalties
		// }
		{
			path: '/company-royalty/',
			component: RoyaltyInfo,
		},
		{
			path: '/company-royalty/:id',
			component: RoyaltyInfo,
		},
	],
	auth: authRoles.productOwner,
};

export default PaymentManagementConfig;
