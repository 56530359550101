/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import history from '@history';
import {
	CircularProgress,
	Avatar,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormHelperText,
	Button,
} from '@material-ui/core';
import { uploadFile } from 'app/services/imageUpload/imageUpload';
import {
	addCompany,
	getSearchableStateList,
	getCountryList,
	getSearchableCityList,
} from 'app/services/Company/companyService';
import * as Actions from 'app/store/actions';
import FuseAnimate from '@fuse/core/FuseAnimate';
import CustomButton from 'app/customComponents/CustomButton/CustomButton';
import { useDispatch } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import './Company.css';
import { v4 as uuidv4 } from 'uuid';
import { getImageUrl } from 'utils/utils';
import tzlookup from 'tz-lookup';

function AddCompany() {
	const dispatch = useDispatch();
	const [form, setForm] = useState({ status: 1 });
	const [errTxts, setErrTxts] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [preview, setPreview] = useState(null);
	const [countries, setCountries] = useState([]);
	const [cityTimezone, setCityTimeZone] = useState('');
	const [latLong, setLatLong] = useState({ lat: '', long: '' });
	const [city, setCity] = useState([]);
	const [states, setStates] = useState([]);
	const inputRef = useRef(null);
	const [isStateloading, setIsStateloading] = useState(false);
	const [searchStateQuery, setStateSearchQuery] = useState('');
	const [isCityLoading, setIsCityLoading] = useState(false);
	const [searchCityQuery, setSearchCityQuery] = useState('');

	const handleChange = (ev) => {
		const { name, value } = ev.target;
		setErrTxts({ ...errTxts, [name]: '' });
		if (name === 'country_code') {
			setErrTxts({ ...errTxts, country_code: '' });
		}
		if (name === 'state_id') {
			setErrTxts({ ...errTxts, state_id: '' });
		}
		if (name === 'city') {
			setErrTxts({ ...errTxts, city: '' });
		}
		if (name === 'zip_code') {
			setErrTxts({ ...errTxts, zip_code: '' });
		}
		setForm({ ...form, [name]: value });
	};

	useEffect(() => {
		if (!selectedFile) {
			setPreview(null);
			return;
		}

		const objectUrl = URL.createObjectURL(selectedFile);
		setPreview(objectUrl);

		return () => URL.revokeObjectURL(objectUrl);
	}, [selectedFile]);

	const onSelectFile = (e) => {
		if (!e.target.files || e.target.files.length === 0) {
			setSelectedFile(null);
			return;
		}

		setSelectedFile(e.target.files[0]);
	};

	const handleSubmit = (ev) => {
		ev.preventDefault();
		setErrTxts({});
		if (!form.name) {
			setErrTxts({ ...errTxts, name: 'This field is required' });
			return;
		}
		if (form.name && /[^a-z A-Z]/.test(form.name)) {
			setErrTxts({ ...errTxts, name: 'Please enter a valid name.' });
			return;
		}
		if (!form.email) {
			setErrTxts({ ...errTxts, email: 'This field is required' });
			return;
		}
		if (!/^\S+@\S+\.\S+$/.test(form.email)) {
			setErrTxts({ ...errTxts, email: 'Please enter valid email' });
			return;
		}
		if (!form.phone) {
			setErrTxts({ ...errTxts, phone: 'This field is required' });
			return;
		}
		if (form.phone) {
			if (
				!Number.isFinite(
					Number(form.phone.split(' ').join('').split('-').join('').split('(').join('').split(')').join('')),
				)
			) {
				setErrTxts({ ...errTxts, phone: 'Please enter valid phone number' });
				return;
			}
		}
		if (!form.address) {
			setErrTxts({ ...errTxts, address: 'This field is required.' });
			return;
		}

		// if (!form.street) {
		// 	setErrTxts({ ...errTxts, street: 'This field is required.' });
		// 	return;
		// }
		if (!form.country_code) {
			setErrTxts({ ...errTxts, country_code: 'This field is required.' });
			return;
		}
		if (!form.state_id) {
			setErrTxts({ ...errTxts, state_id: 'This field is required.' });
			return;
		}
		if (!form.city) {
			setErrTxts({ ...errTxts, city: 'This field is required.' });
			return;
		}
		if (!form.zip_code) {
			setErrTxts({ ...errTxts, zip_code: 'This field is required.' });
			return;
		}
		if (typeof form.zip_code !== 'number' && Number.isInteger(form.zip_code)) {
			setErrTxts({ ...errTxts, zip_code: 'Zipcode must be a number' });
			return;
		}
		if (selectedFile) {
			const filename = getImageUrl(selectedFile);
			setIsLoading(true);
			uploadFile(selectedFile, filename).then((response) => {
				form.file = `${import.meta.env.VITE_S3_BASE_URL}${response}`;
				const payload = {
					name: form.name,
					address: form.address,
					email: form.email,
					state_id: form.state_id,
					street: form.street,
					city: form.city,
					zip_code: form.zip_code,
					time_zone: cityTimezone,
					country_code: form.country_code,
					status: form.status,
					file: form.file,
					phone: form.phone,
				};
				addCompany(payload)
					.then((resp) => {
						dispatch(
							Actions.showMessage({
								message: resp.data.message,
								autoHideDuration: 1500,
								variant: 'success',
							}),
						);
						history.goBack();
					})
					.catch((err) => {
						if (err.response?.data?.errors) {
							setErrTxts(err.response.data.errors);
						} else {
							dispatch(
								Actions.showMessage({
									message: 'Failed to Add Company.',
									autoHideDuration: 1500,
									variant: 'error',
								}),
							);
						}
					})
					.finally(() => setIsLoading(false));
			});
		} else {
			setIsLoading(true);
			const payload = {
				name: form.name,
				address: form.address,
				email: form.email,
				state_id: form.state_id,
				street: form.street,
				city: form.city,
				zip_code: form.zip_code,
				time_zone: cityTimezone,
				country_code: form.country_code,
				status: form.status,
				phone: form.phone,
			};
			addCompany(payload)
				.then((resp) => {
					dispatch(
						Actions.showMessage({
							message: resp.data.message,
							autoHideDuration: 1500,
							variant: 'success',
						}),
					);
					history.goBack();
				})
				.catch((err) => {
					if (err.response?.data?.errors) {
						setErrTxts(err.response.data.errors);
					} else {
						dispatch(
							Actions.showMessage({
								message: 'Failed to Add Company.',
								autoHideDuration: 1500,
								variant: 'error',
							}),
						);
					}
				})
				.finally(() => setIsLoading(false));
		}
	};

	useEffect(() => {
		if (latLong.lat !== '' && latLong.long !== '') {
			setCityTimeZone(tzlookup(latLong.lat, latLong.long));
		}
	}, [latLong]);

	useEffect(() => {
		let isMounted = true;

		const timeout = setTimeout(() => {
			if (!isMounted) return;
			setIsCityLoading(true);
			setForm({ ...form, city: '' });
			setCity([]);
			if (!searchCityQuery && !form.state_id) {
				setForm({ ...form, city: '' });
				setCity([]);
			} else {
				getSearchableCityList(form.state_id, searchCityQuery, 1)
					.then((res) => {
						if (!isMounted) return;
						setForm({ ...form, city: '' });
						setCity(res.data.data);
					})
					.catch((err) => {
						if (!isMounted) return;
						dispatch(
							Actions.showMessage({
								message: 'Failed to get cities.',
								autoHideDuration: 1500,
								variant: 'error',
							}),
						);
					})
					.finally(() => {
						if (!isMounted) return;
						setIsCityLoading(false);
					});
			}
		}, 1000);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
	}, [form.state_id, searchCityQuery]);

	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(() => {
			if (!isMounted) return;
			setIsStateloading(true);
			setStates([]);
			setForm({ ...form, city: '' });
			setCity([]);
			if (!searchStateQuery) {
				getSearchableStateList('', '')
					.then((res) => {
						if (!isMounted) return;
						setStates(res.data.data);
						setForm({ ...form, city: '' });
						setCity([]);
					})
					.catch((err) => {
						if (!isMounted) return;
						dispatch(
							Actions.showMessage({
								message: 'Failed to get states.',
								autoHideDuration: 1500,
								variant: 'error',
							}),
						);
					})
					.finally(() => {
						if (!isMounted) return;
						setIsStateloading(false);
					});
			} else {
				getSearchableStateList(searchStateQuery, searchStateQuery ? undefined : 1)
					.then((res) => {
						if (!isMounted) return;
						setStates(res.data.data);
						setForm({ ...form, city: '' });
					})
					.catch((err) => {
						if (!isMounted) return;
						dispatch(
							Actions.showMessage({
								message: 'Failed to get states.',
								autoHideDuration: 1500,
								variant: 'error',
							}),
						);
					})
					.finally(() => {
						if (!isMounted) return;
						setIsStateloading(false);
					});
			}
		}, 1000);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
	}, [dispatch, form.country_code, searchStateQuery]);

	useEffect(() => {
		let isMounted = true;
		getCountryList()
			.then((res) => {
				if (!isMounted) return;
				setCountries(res.data);
			})
			.catch((err) => {
				if (!isMounted) return;
				dispatch(
					Actions.showMessage({
						message: 'Failed to get countries.',
						autoHideDuration: 1500,
						variant: 'error',
					}),
				);
			});
		return () => {
			isMounted = false;
		};
	}, []);

	return (
		<FuseAnimate animation="transition.slideLeftIn" duration={600}>
			<div className="add-company-cont mx-auto">
				<div>
					<div className="flex gap-10">
						<Button
							onClick={() => {
								history.push({
									state: 'Open messaging',
									pathname: '/Company',
								});
							}}
						>
							<img
								alt="Go Back"
								className="cursor-pointer"
								src="assets/images/arrow-long.png"
								style={{ width: '25px' }}
							/>
						</Button>
						<h2 className="font-bold" style={{ fontSize: '20px' }}>
							<span className=""></span>
							Add Company
						</h2>
					</div>
				</div>
				<form className="bg-white rounded add-company-form-cont" onSubmit={handleSubmit}>
					<div className="grid grid-cols-4 mt-32" style={{ gap: 20 }}>
						<div
							onClick={() => inputRef.current.click()}
							className="row-span-2 camera-holder"
							style={{ justifySelf: 'center' }}
						>
							<Avatar src={preview} style={{ width: 120, height: 120 }} />
							<div className="company-pp-overlay">
								<i className="fa fa-2x fa-camera" />
							</div>
							<input
								onChange={onSelectFile}
								type="file"
								name="file"
								id="image"
								className="hidden"
								ref={inputRef}
							/>
						</div>
						<TextField
							error={!!errTxts.name?.length}
							helperText={errTxts.name}
							name="name"
							value={form.name || ''}
							onChange={handleChange}
							className="w-5/6 fontbold"
							style={{ fontWeight: 800 }}
							label="Name of Company"
							id="name"
						/>
						<TextField
							error={!!errTxts.email?.length}
							helperText={errTxts.email}
							name="email"
							id="email"
							onChange={handleChange}
							value={form.email || ''}
							className="w-5/6"
							label="Email Address"
						/>
						<TextField
							error={!!errTxts.phone?.length}
							helperText={errTxts.phone}
							name="phone"
							id="phone"
							onChange={handleChange}
							value={form.phone || ''}
							className="w-5/6"
							label="Contact Number"
						/>
						{/* <TextField
							name="total_no_of_school"
							disabled
							value={0}
							className="w-5/6 hidden"	// Hidden Textfield
							label="Total No of Schools"
						/>
						<TextField
							name="total_active_subscribers"
							value={10}
							disabled
							className="w-5/6 hidden"	// Hidden Textfield
							label="Total Active Subscribers"
						/> */}
						<TextField
							error={!!errTxts.address?.length}
							helperText={errTxts.address}
							value={form.address || ''}
							onChange={handleChange}
							className="w-5/6"
							name="address"
							id="address"
							label="Address 1"
						/>
						<TextField
							// error={!!errTxts.street?.length}
							// helperText={errTxts.street}
							onChange={handleChange}
							value={form.street || ''}
							className="w-5/6"
							name="street"
							id="street"
							label="Address 2"
						/>
						<FormControl error={!!errTxts.country_code} className="w-5/6">
							<InputLabel id="country_code">Country</InputLabel>
							<Select
								error={!!errTxts.country_code}
								value={form.country_code || ''}
								name="country_code"
								onChange={handleChange}
								id="country_code"
							>
								{countries.length ? (
									countries.map((country) => {
										return (
											<MenuItem key={country.id} value={country.code}>
												{country.name}
											</MenuItem>
										);
									})
								) : (
									<MenuItem>Loading...</MenuItem>
								)}
							</Select>
							{errTxts.country_code && <FormHelperText>{errTxts.country_code}</FormHelperText>}
						</FormControl>
						<div />
						<Autocomplete
							id="state-autocomplete"
							name="state_id"
							options={states}
							renderOption={(option) => (
								<>
									<div className="flex" style={{ gap: 10 }}>
										<div>{option.name}</div>
									</div>
								</>
							)}
							getOptionLabel={(option) => option.name}
							autoComplete={false}
							clearOnBlur={false}
							disableClearable
							loading={isStateloading}
							loadingText="...Loading"
							sx={{ width: '100%' }}
							onChange={(_e, v) => {
								setForm({ ...form, state_id: v?.id || '' });
							}}
							onInputChange={(e, value) => {
								setStateSearchQuery(value);
								if (value === '') {
									setForm({ ...form, state_id: '', city: '' });
								}
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label="State"
									className="w-5/6"
									name="state_id"
									error={errTxts.state_id}
									helperText={errTxts.state_id}
									autoComplete="off"
								/>
							)}
						/>
						<Autocomplete
							id="city-autocomplete"
							name="city"
							options={city}
							renderOption={(option) => (
								<>
									<div className="flex" style={{ gap: 10 }}>
										<div>{option.name}</div>
									</div>
								</>
							)}
							getOptionLabel={(option) => option.name}
							autoComplete={false}
							clearOnBlur={false}
							disableClearable
							loading={isCityLoading}
							loadingText="...Loading"
							sx={{ width: '100%' }}
							onChange={(_e, v) => {
								const value = JSON.parse(v?.meta);
								setLatLong({
									...latLong,
									lat: value.latitude,
									long: value.longitude,
								});
								setForm({ ...form, city: v?.name || '' });
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label="City"
									className="w-5/6"
									onChange={(e) => {
										setSearchCityQuery(e.target.value);
										if (e.target.value === '') {
											setForm({ ...form, city: '' });
										}
									}}
									error={!!errTxts.city}
									helperText={errTxts.city}
									autoComplete="off"
									name="city"
								/>
							)}
						/>
						<TextField
							error={!!errTxts.zip_code}
							helperText={errTxts.zip_code}
							onChange={handleChange}
							value={form.zip_code}
							className="w-5/6"
							name="zip_code"
							id="zip_code"
							label="Zipcode"
						/>
						<div />
						<FormControl className="w-5/6">
							<InputLabel id="status">Status</InputLabel>
							<Select
								error={!!errTxts.status}
								name="status"
								value={form.status}
								onChange={handleChange}
								labelId="status"
								id="status"
								placeholder="Status"
							>
								<MenuItem selected value={1}>
									<span id="active">Active</span>
								</MenuItem>
								<MenuItem value={0}>
									<span id="disable">Inactive</span>
								</MenuItem>
							</Select>
						</FormControl>
					</div>

					<div className="btnedit" style={{ marginTop: '33px' }}>
						{!isLoading ? (
							<div className=" center-btn">
								<CustomButton
									variant="secondary"
									width="140px"
									onClick={() => {
										history.goBack();
									}}
								>
									Cancel
								</CustomButton>

								<CustomButton variant="primary" type="submit" width="140px" fontSize="15px">
									Add
								</CustomButton>
							</div>
						) : (
							<div className="flex justify-center">
								<CircularProgress className="mx-auto" />
							</div>
						)}
					</div>
				</form>
			</div>
		</FuseAnimate>
	);
}

export default AddCompany;
