import React from 'react';
import * as Actions from 'app/store/actions';
import './DisableConfirmDialog.css';
import { useDispatch } from 'react-redux';
function ConfirmationPopup({ callback, message }) {
	const dispatch = useDispatch();
	return (
		<div className="popup">
			<div className="px-16">
				<div className="icon-span">
					<h2>!</h2>
				</div>
				<h1 className="disable-text">{message}</h1>
				<button type="button" className="no-disable-btn" onClick={() => dispatch(Actions.closeDialog())}>
					No
				</button>
				<button
					type="button"
					className="no-disable-btn"
					onClick={() => {
						dispatch(Actions.closeDialog());
						callback();
					}}
				>
					Yes
				</button>
			</div>
		</div>
	);
}

export default ConfirmationPopup;
