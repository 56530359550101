import authRoles from 'app/auth/authRoles';
import MapViewOfSchools from './MapViewOfSchools';

const MapViewConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: '/MapView',
			component: MapViewOfSchools,
		},
	],
	auth: authRoles.productOwner,
};

export default MapViewConfig;
