import React from 'react';
import authRoles from 'app/auth/authRoles';
import Packages from './Packages';
import PackageEdit from './PackageEdit';

const PackagesConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: '/Packages',
			component: Packages,
		},
		{
			path: '/PackageEdit/:id',
			component: PackageEdit,
		},
	],
	auth: authRoles.productOwner,
};

export default PackagesConfig;
